import React, { useState, useCallback, useContext, useEffect } from 'react';
import useLocalStorage from '../../hooks/useLocalstorage';
import { URLPathContext } from '../../App';
import styled from 'styled-components';
import { Buttons, HeaderContainer, BackButton, ValidationAlert } from '../../style';
import { SmallInput, PageHeader, Label, Button, Content } from 'scorer-ui-kit';

const Container = styled(Content)<{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '295px'};
  height: 100%;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  padding: 67px 50px 25px 45px;
  transition: 0.2s;
`;

const InputContainer = styled.div`
  display: flex;
  width: inherit;
  margin: 5px 0 0 0;
  justify-content: space-between;
  height: 100px;
`;

const OptionContainer = styled.div`
  display: flex;
  width: inherit;
  margin: 5px 0 0 0;
  justify-content: space-between;
  height: 400px;
`;

const AreaInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 0 10px 0;
`;

const DelButton = styled(Button)`
  margin-top: 5px;
  //margin-bottom: 20px;
`;

const Position = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 0 10px 0;
`;

const SmallInputContainer = styled.div`
  display: flex;
  width: auto;
  margin: 0 5px 0 0;
`;

const SelectFieldBox = styled.select`
  width: 130px;
  height: 30px;
  padding: 0 0 0 10px;
  border-radius: 3px;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  color: #767676;
  margin-top: 5px;
  //margin-bottom: 20px;
`;

const SmallInputBox = styled(SmallInput)`
  width: 130px;
  padding: 0 14px 0 0;
  border-radius: 3px;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  color: #767676;
  margin-top: -5px;

  margin-bottom: -20px;
`;

const InputHeader = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #7f7f7f;
  margin-bottom: 7px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldLabel = styled(Label)`
  height: 10px;
  margin: 2px 0 8px 2px;
  font-size: 12px;
  color: #767676;
  white-space: nowrap;
`;

const EmptyBox = styled.label`
  margin-bottom: 55px;
`;

const filter_TypeTemplate = [
  ['エリア滞在秒数', 'エリア合計滞在秒数', 'マップ滞在秒数', 'エリア滞在比率',/* '移動時間',*/ 'ポーズ判定', '視聴時間', '視聴可能時間', '年齢', '性別'],
  ['time_area', 'totaltime_area', 'totaltime_map', 'staying_rate',/* 'time_to_next_area',*/ 'pose_class', 'watch_time', 'watchable_time', 'age', 'gender']
];

const AnalyzeStep1 = () => {
  const { setNowURL } = useContext(URLPathContext);
  const [validationAlert, setValidationAlert] = useState<{key?: any, message: string, type: 'success' | 'error' | 'warning' | 'info' | undefined }>({ key: new Date(), message: '', type: undefined });
  const { floorPlanStorage, setFloorPlanStorage } = useLocalStorage();
  const [ selectedFilter, setSelectedFilter ] = useState(0);
  const [ filterSets, setFilterSets ] = useState(floorPlanStorage.filterSets.length!==0 ? floorPlanStorage.filterSets : []);
  const [ filters, setFilters ] = useState(floorPlanStorage.filters.length!==0 ? floorPlanStorage.filters : [{
    'id': 0,
    'name': 'area_filter_0',
    'area_id': floorPlanStorage.filterAreas.length !== 0 ? floorPlanStorage.filterAreas[0].id : 0,
    'filter': [{
      'type': 'time_area',
      'subtype': 'range',
      'min_value': 10,
      'max_value': 100,
      'threshold_value': 60,
      'gender': 'unknow',
      'pose_value': 5,
      'pose_class': floorPlanStorage.pose_list.length !== 0 ? floorPlanStorage.pose_list[0] : 'none',
    }]
  }]);

  // ジョブ追加
  const addFilter = useCallback(() => {
    const newFilters = {
      'id': filters.length,
      'name': '',
      'area_id': floorPlanStorage.filterAreas.length !== 0 ? floorPlanStorage.filterAreas[0].id : 0,
      'filter': [{
        'type': 'time_area',
        'subtype': 'range',
        'min_value': 10,
        'max_value': 100,
        'threshold_value': 60,
        'gender': 'unknow',
        'pose_value': 5,
        'pose_class': floorPlanStorage.pose_list.length !== 0 ? floorPlanStorage.pose_list[0] : 'none',
      }]
    };
    // idに重複しているかどうかチェック.既存filterを削除した場合、id名前重複してしまうことがあるため
    const idList = [];
    for(let i = 0; i < filters.length; i++) { // filter id取得
      idList.push(filters[i].id);
    }
    for(let i = 0; i < idList.length; i++) {
      if (idList.indexOf(i) === -1){
        newFilters.id = i;
        break;
      }
    }
    newFilters.name = 'area_filter_' + newFilters.id;
    setFilters([...filters, newFilters]);
    setSelectedFilter(filters.length);
  }, [filters, floorPlanStorage]);

  //ジョブ削除
  const delFilter = useCallback(() => {
    if (filters.length === 1) {
      return false;
    }

    //filterを削除したら,filterSetsとの関連付けも削除
    if (floorPlanStorage.filterSets){
      const new_filterSets = JSON.parse(JSON.stringify(floorPlanStorage.filterSets));
      for (let i = new_filterSets.length-1; i >= 0; i--) {
        for (let index = new_filterSets[i].filterList.length-1; index >= 0; index--){
          if (new_filterSets[i].filterList[index].id === filters[selectedFilter].id){
            new_filterSets[i].filterList.splice(index, 1);
          }
        }
      }
      setFloorPlanStorage({'filterSets': new_filterSets});
    }

    const newFilters = [...filters];
    newFilters.splice(selectedFilter, 1);
    setFilters(newFilters);
    setSelectedFilter(0);
  }, [filters, selectedFilter, floorPlanStorage, setFloorPlanStorage]);

  //今設定中のfilterIDを取得
  const getselectedFilter = useCallback((ev) => {
    for(let i=0; i<filters.length; i++) {
      if(filters[i].name === ev.target.value) {
        setSelectedFilter(i);
        break;
      }
    }
  }, [filters]);

  //設定名変更
  const renameFilter = useCallback((ev) => {
    const newFilters = [...filters];
    newFilters[selectedFilter].name = ev.target.value;
    setFilters(newFilters);
  }, [filters, selectedFilter]);

  //エリア変更
  const changeArea = useCallback((ev) => {
    const newFilters = [...filters];
    newFilters[selectedFilter].area_id = Number(ev.target.value);
    setFilters(newFilters);
  }, [filters, selectedFilter]);



  /*---------詳細設定---------*/
  //filter条件変更
  const changeOption0 = useCallback(({ target: { value } }, index) => {
    const newFilters = [...filters];
    newFilters[selectedFilter].filter[index].type = value;
    setFilters(newFilters);
  }, [filters, selectedFilter]);

  //Option1変更
  const changeOption1 = useCallback(({ target: { value } }, index) => {
    const newFilters = [...filters];
    /*if (newFilters[selectedFilter].filter[index].type === 'time_to_next_area'){
      if ( isNaN(Number(value)) ) return;
      newFilters[selectedFilter].filter[index].fixed_value = Number(value);
    }*/
    if (newFilters[selectedFilter].filter[index].type === 'gender'){
      newFilters[selectedFilter].filter[index].gender = value;
    }
    else if (newFilters[selectedFilter].filter[index].type === 'pose_class'){
      newFilters[selectedFilter].filter[index].pose_class = value;
    }
    else{
      newFilters[selectedFilter].filter[index].subtype = value;
    }
    setFilters(newFilters);
  }, [filters, selectedFilter]);

  //Option2変更
  const changeOption2 = useCallback(({ target: { value } }, index) => {
    if ( isNaN(Number(value)) ) return false;

    const newFilters = [...filters];
    if (newFilters[selectedFilter].filter[index].type === 'pose_class'){
      if (Number(value) > 10 || Number(value) < 1) return;
      newFilters[selectedFilter].filter[index].pose_value = Number(value);
    }
    else{
      if (newFilters[selectedFilter].filter[index].type === 'age'){
        if (Number(value) > 200 || Number(value) < 1) return;
      }
      switch(newFilters[selectedFilter].filter[index].subtype){
      case 'range':
        if (value <= newFilters[selectedFilter].filter[index].max_value){
          newFilters[selectedFilter].filter[index].min_value = Number(value);
        }else{
          return false;
        }
        break;
      case 'max':
        newFilters[selectedFilter].filter[index].threshold_value = Number(value);
        break;
      case 'min':
        newFilters[selectedFilter].filter[index].threshold_value = Number(value);
        break;
      }
    }
    setFilters(newFilters);
  }, [filters, selectedFilter]);

  //Option3変更
  const changeOption3 = useCallback(({ target: { value } }, index) => {
    if ( isNaN(Number(value)) ) return false;
    const newFilters = [...filters];

    if (newFilters[selectedFilter].filter[index].type === 'age'){
      if (Number(value) > 200 || Number(value) < 1) return;
    }
  
    newFilters[selectedFilter].filter[index].max_value = Number(value);
    setFilters(newFilters);
  }, [filters, selectedFilter]);

  //filter追加
  const addOption = useCallback(() => {
    const newFilters = [...filters];
    const own_optional_List = [];
    for(let i=0; i<newFilters[selectedFilter].filter.length; i++){
      own_optional_List.push(newFilters[selectedFilter].filter[i].type);
    }

    for(let k=0; k<filter_TypeTemplate[1].length; k++){
      if (own_optional_List.indexOf(filter_TypeTemplate[1][k]) === -1){
        newFilters[selectedFilter].filter.push({
          'type': filter_TypeTemplate[1][k],
          'subtype': 'range',
          'min_value': 10,
          'max_value': 100,
          'threshold_value': 60,
          'gender': 'unknow',
          'pose_value': 5,
          'pose_class': floorPlanStorage.pose_list[0],
        });

        setFilters(newFilters);
        return;
      }
    }
  }, [filters, selectedFilter, floorPlanStorage.pose_list]);

  //filter削除
  const delOption = useCallback((index) => {
    if (filters[selectedFilter].filter.length === 1) return;
    const newFilters = [...filters];
    newFilters[selectedFilter].filter.splice(index, 1);
    setFilters(newFilters);
  }, [filters, selectedFilter]);




  /*-------------------route-------------------*/
  const onContinue = useCallback((url: string) => {
    for (let i=0; i < filters.length; i++) {
      if (filters[i].filter.length === 0){
        setValidationAlert({ key: new Date(), message: '設定が漏れています。正しく設定してください。', type: 'error'});
        return;
      }
    }
    setFloorPlanStorage({'filters': filters});
    setFloorPlanStorage({'filterSets': filterSets});
    setNowURL(url);
  }, [filters, filterSets, setFloorPlanStorage, setNowURL]);
  /*-------------------route-------------------*/

  // filter設定が変更したら,filterSetsで順番設定済みのfilterも変更
  useEffect(() => {
    if (floorPlanStorage.filterSets){
      const new_filterSets = JSON.parse(JSON.stringify(floorPlanStorage.filterSets));
      for (let i = new_filterSets.length-1; i >= 0; i--) {  // filterSetsごとにチェック
        for (let index = new_filterSets[i].filterList.length-1; index >= 0; index--){  // filterSetsのfilterListは順番設定
          for (let k = filters.length-1; k >= 0; k--) {  // 順番付け済みのfilterを更新
            if (new_filterSets[i].filterList[index].id === filters[k].id){
              new_filterSets[i].filterList[index] = JSON.parse(JSON.stringify(filters[k]));
            }
          }
        }
      }
      setFilterSets(new_filterSets);
    }
  }, [filters, floorPlanStorage]);


  return (
    <Container>
      <HeaderContainer>
        <PageHeader title='対象人物抽出設定：滞在エリア' />
        <Buttons>
          <BackButton size='small' design='secondary' onClick={() => onContinue('/main')}>戻る</BackButton>
          <Button design='primary' size='small' onClick={() => onContinue('/analyze-step2')}>次：順番設定</Button>
        </Buttons>
      </HeaderContainer>

      {validationAlert.message !== '' &&
        <ValidationAlert type={validationAlert.type} message={validationAlert.message} />}

      <InputContainer>
        <AreaInfo>
          <InputHeader>設定追加</InputHeader>
          <SmallInputContainer>
            <InputField>
              <Button design='primary' size='small' onClick={addFilter}>追加</Button>
            </InputField>
          </SmallInputContainer>
        </AreaInfo>
        <AreaInfo>
          <InputHeader>設定選択</InputHeader>
          <SmallInputContainer>
            <InputField>
              <SelectFieldBox value={filters[selectedFilter].name} onChange={getselectedFilter}>
                {filters.map((filter: any)=>{
                  return <option key={'select_filter_'+filter.id} value={filter.name}>{filter.name}</option>;
                })}
              </SelectFieldBox>
            </InputField>
          </SmallInputContainer>
        </AreaInfo>
        <AreaInfo>
          <InputHeader>設定名編集</InputHeader>
          <SmallInputContainer>
            <InputField>
              <SmallInputBox label='' value={filters[selectedFilter].name} onChange={renameFilter} />
            </InputField>
          </SmallInputContainer>
        </AreaInfo>
        <AreaInfo>
          <InputHeader>滞在エリア指定</InputHeader>
          <SmallInputContainer>
            <InputField>
              <SelectFieldBox value={filters[selectedFilter].area_id} onChange={changeArea}>
                {floorPlanStorage.filterAreas.map((area: any, i) => {
                  return <option key={'areas_'+selectedFilter+i} value={area.id}>{area.data.name}</option>;
                })}
              </SelectFieldBox>
            </InputField>
          </SmallInputContainer>
        </AreaInfo>
        <AreaInfo>
          <InputHeader>設定削除</InputHeader>
          <SmallInputContainer>
            <InputField>
              <Button design='primary' size='small' onClick={delFilter}>削除</Button>
            </InputField>
          </SmallInputContainer>
        </AreaInfo>
      </InputContainer>

      <OptionContainer>
        <Position>
          <InputHeader>詳細抽出条件</InputHeader>
          <SmallInputContainer>
            <InputField>
              {filters[selectedFilter].filter.map((conf: any, i)=>{
                const optional_List = [...filter_TypeTemplate[1]];
                for(let index=0; index<filters[selectedFilter].filter.length; index++){
                  if ( optional_List.indexOf(filters[selectedFilter].filter[index].type) !== -1 ){
                    optional_List.splice(optional_List.indexOf(filters[selectedFilter].filter[index].type), 1);
                  }
                }
                return (
                  <>
                    <FieldLabel htmlFor='' labelText='条件選択' />
                    <SelectFieldBox key={'select0_'+conf.type+selectedFilter+i} value={conf.type} onChange={(event) => changeOption0(event, i)}>
                      <option key={'select0_option_'+conf.type+selectedFilter+i} value={conf.type}>{filter_TypeTemplate[0][filter_TypeTemplate[1].indexOf(conf.type)]}</option>;
                      {optional_List.map((item: any, k)=>{
                        return <option key={'select0_option_'+item+selectedFilter+i+k} value={item}>{filter_TypeTemplate[0][filter_TypeTemplate[1].indexOf(item)]}</option>;
                      })}
                    </SelectFieldBox>
                  </>);
              })}
            </InputField>
          </SmallInputContainer>
        </Position>

        <Position>
          <InputHeader>設定項目1</InputHeader>
          <SmallInputContainer>
            <InputField>
              {filters[selectedFilter].filter.map((conf: any, i)=>{
                return (
                  <>
                    {/*conf.type === 'time_to_next_area' ?
                      <>
                        <FieldLabel htmlFor='' labelText='次エリアまでの最大移動時間(秒)' />
                        <SmallInputBox label='' value={conf.fixed_value} onChange={(event) => changeOption1(event, i)} />
                    </>*/}
                    {conf.type === 'gender' ?
                      <>
                        <FieldLabel htmlFor='' labelText='性別指定' />
                        <SelectFieldBox key={'select1_gender_'+conf.type+selectedFilter+i} value={conf.gender} onChange={(event) => changeOption1(event, i)}>
                          <option value='male'>男性</option>
                          <option value='female'>女性</option>
                        </SelectFieldBox>
                      </>
                      : conf.type === 'pose_class' ?
                        <>
                          <FieldLabel htmlFor='' labelText='ポーズ指定' />
                          <SelectFieldBox key={'select1_'+conf.type+selectedFilter+i} value={conf.pose_class} onChange={(event) => changeOption1(event, i)}>
                            {floorPlanStorage.pose_list.map((pose: any, i) => <option key={'select1_pose_class_'+i+pose} value={pose}>{pose}</option>)}
                          </SelectFieldBox>
                        </>
                        :
                        <>
                          <FieldLabel
                            htmlFor=''
                            labelText={
                              conf.type === 'watch_time' ? '視聴時間'
                                : conf.type === 'watchable_time' ? '視聴可能時間'
                                  : conf.type === 'age' ? '年齢'
                                    : '滞在時間'
                            }
                          />
                          <SelectFieldBox key={'select1_'+conf.type+selectedFilter+i} value={conf.subtype} onChange={(event) => changeOption1(event, i)}>
                            <option value='range'>範囲</option>
                            <option value='min'>最小閾値</option>
                            <option value='max'>最大閾値</option>
                          </SelectFieldBox>
                        </>}
                  </>
                );
              })}
            </InputField>
          </SmallInputContainer>
        </Position>

        <Position>
          <InputHeader>設定項目2</InputHeader>
          <SmallInputContainer>
            <InputField>
              {filters[selectedFilter].filter.map((conf: any, i)=>{
                return (
                  <>
                    {/*conf.type === 'time_to_next_area' ?*/}
                    {conf.type === 'gender' ?
                      <EmptyBox />
                      : conf.type === 'pose_class' ?
                        <>
                          <FieldLabel htmlFor='' labelText='判定閾値(1-10)以上' />
                          <SmallInputBox
                            key={'select2_subtype_pose_class_'+conf.id} label=''
                            value={conf.pose_value}
                            onChange={(event) => changeOption2(event, i)}
                          />
                        </>
                        : conf.subtype === 'range' ?
                          <>
                            <FieldLabel
                              htmlFor=''
                              labelText={
                                conf.type === 'staying_rate' ? '最小比率(%)'
                                  : conf.type === 'age' ? '最小年齢'
                                    : '最小時間(秒)'
                              }
                            />
                            <SmallInputBox
                              key={'select2_subtype_range_min_'+conf.id} label=''
                              value={conf.min_value}
                              onChange={(event) => changeOption2(event, i)}
                            />
                          </>
                          : conf.subtype === 'max' ?
                            <>
                              <FieldLabel
                                htmlFor=''
                                labelText={
                                  conf.type === 'staying_rate' ? '比率閾値(%)'
                                    :conf.type === 'age' ? '最大年齢'
                                      :'時間閾値(秒)'
                                }
                              />
                              <SmallInputBox
                                key={'select2_subtype_MaxThreshold'+conf.id} label=''
                                value={conf.threshold_value}
                                onChange={(event) => changeOption2(event, i)}
                              />
                            </>
                            : conf.subtype === 'min' &&
                              <>
                                <FieldLabel
                                  htmlFor=''
                                  labelText={
                                    conf.type === 'staying_rate' ? '比率閾値(%)'
                                      : conf.type === 'age' ? '最小年齢'
                                        :'時間閾値(秒)'
                                  }
                                />
                                <SmallInputBox
                                  key={'select2_subtype_MinThreshold'+conf.id} label=''
                                  value={conf.threshold_value}
                                  onChange={(event) => changeOption2(event, i)}
                                />
                              </>}
                  </>
                );
              })}
            </InputField>
          </SmallInputContainer>
        </Position>

        <Position>
          <InputHeader>設定項目3</InputHeader>
          <SmallInputContainer>
            <InputField>
              {filters[selectedFilter].filter.map((conf: any, i)=>{
                return (
                  <>
                    {/*conf.type === 'time_to_next_area' || */}
                    {(conf.type === 'pose_class' || conf.type === 'gender' || conf.subtype !== 'range') ?
                      <EmptyBox />
                      :
                      <>
                        <FieldLabel
                          htmlFor=''
                          labelText={
                            conf.type === 'staying_rate' ? '最大比率(%)'
                              : conf.type === 'age' ? '最大年齢'
                                : '最大時間(秒)'
                          }
                        />
                        <SmallInputBox
                          key={'select3_subtype_range_max_'+conf.id} label=''
                          value={conf.max_value}
                          onChange={(event) => changeOption3(event, i)}
                        />
                      </>}
                  </>
                );
              })}
            </InputField>
          </SmallInputContainer>
        </Position>

        <Position>
          <InputHeader>操作</InputHeader>
          {filters[selectedFilter].filter.map((conf: any, i)=>{
            return (
              <SmallInputContainer key={'del_filter_conf'+selectedFilter+i}>
                <InputField>
                  <FieldLabel htmlFor='' labelText='削除' />
                  <DelButton key={'delbtnfilter_conf'+selectedFilter+i} size='small' design='primary' onClick={() => delOption(i)}>－</DelButton>
                </InputField>
              </SmallInputContainer>);
          })}
          <SmallInputContainer>
            {filters[selectedFilter].filter.length < 7 ?
              <InputField>
                <FieldLabel htmlFor='' labelText='追加' />
                <DelButton size='small' design='primary' onClick={addOption}>＋</DelButton>
              </InputField>
              : <></>}
          </SmallInputContainer>
        </Position>
      </OptionContainer>
    </Container>
  );
};

export default AnalyzeStep1;
