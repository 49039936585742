import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
// import useLocalStorage from './hooks/useLocalstorage';
import Sidebar from './components/Sidebar';
// import { getConfig } from './apis';

import './App.css';
import styled, { ThemeProvider } from 'styled-components';
import { lightTheme } from 'scorer-ui-kit';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';

import Main from './pages/Main';
import FilterArea from './pages/FilterArea';
import StaffArea from './pages/StaffArea';
import PeopleTable from './pages/PeopleTable';
import PersonDetails from './pages/PersonDetails';
import AnalyzeStep1 from './pages/job/Analyze-step1';
import AnalyzeStep2 from './pages/job/analyze-step2';
import AnalyzeStep3 from './pages/job/analyze-step3';
import AnalyzeStep4 from './pages/job/analyze-step4';


const PageLayout = styled.div`
  display: flex;
  min-width: 100%;
  min-height: 100vh;
  margin: 0px;
  height: 1000px;
  background: #eff4f7;
`;

// const AlertContainer = styled.div`
//   margin-left: 290px;
//   width: 350px;
//   position: absolute;
// `;


export const URLPathContext = React.createContext<URLPathProps>({
  nowURL: '',
  setNowURL: () => { },
});

export const ThemeContext = React.createContext<any>({
  newTheme: lightTheme,
  changeTheme: () => { }
});

function App() {
  const { push } = useHistory();
  const [nowURL, setNowURL] = useState<string>('/people-table');
  const [newTheme, changeTheme] = useState(lightTheme);
  // const [loading, setLoading] = useState(true);
  // const [alert, setAlert] = useState<Alert>({ message: '', type: 'error' });
  // const { floorPlanStorage, setFloorPlanStorage, deleteFloorPlanStorage } = useLocalStorage();
  // const { floorPlanStorage } = useLocalStorage();
  const [jsonQuery, setjsonQuery] = useState<any>({});
    
    
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if(params){
      setjsonQuery({
        pid: params.get('pid'),
        // startDate: params.get('startDate'),
        // endDate: params.get('endDate')
      });
    }
  },[]);  

  // initialize data from db
  useEffect(() => {
    const timeoutID = window.setTimeout(() => {
      if (jsonQuery.pid/* && jsonQuery.startDate && jsonQuery.endDate*/){
        // setNowURL(`/person-details?pid=${jsonQuery.pid}&startDate=${jsonQuery.startDate}&endDate=${jsonQuery.endDate}`);
        setNowURL(`/person-details?pid=${jsonQuery.pid}`);
      }
    }, 1000);
    return () => window.clearTimeout(timeoutID);
  }, [jsonQuery]);
    

  // handle path url
  useEffect(() => {
    push(nowURL);
  }, [nowURL, push]);


  return (
    <ThemeContext.Provider value={{ newTheme, changeTheme }}>
      {/* <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop> */}

      <ThemeProvider theme={newTheme}>
        <PageLayout>
          {/* {(Object.keys(floorPlanStorage).length !== 0 && loading === false) && */}
          <URLPathContext.Provider value={{ nowURL, setNowURL }}>
            <Sidebar />
            <Switch>
              <Route path='/main' exact component={Main} />
              <Route path='/filter-area' exact component={FilterArea} />
              <Route path='/staff-area' exact component={StaffArea} />
              <Route path='/people-table' exact component={PeopleTable} />
              <Route path='/person-details' exact component={PersonDetails} />
              <Route path='/analyze-step1' exact component={AnalyzeStep1} />
              <Route path='/analyze-step2' exact component={AnalyzeStep2} />
              <Route path='/analyze-result' exact component={AnalyzeStep3} />
              <Route path='/analyze-detail' exact component={AnalyzeStep4} />
            </Switch>
          </URLPathContext.Provider>
          {/* </URLPathContext.Provider>} */}
        </PageLayout>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
}

export default App;
