import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { Content, Button, AlertBar } from 'scorer-ui-kit';

const GlobalStyle = createGlobalStyle`

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  5% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  15% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  background: transparent;
  padding: 0 37px 0 42px;
  outline: none;
  border: 0;
  overflow: auto;
  margin: 0;
  width: 761px !important;
}

.popup-overlay {
  background-color: hsla(202,33%,95%,0.51);
  backdrop-filter: blur(5px);
  width: auto;
  height: auto;
  overflow: auto;
  z-index: 99999 !important;
}

.image-gallery-play-button {
  right: -41px !important;
  top: 8px !important;
  left: auto !important;
  bottom: auto !important;
  padding 0px !important;
}

.image-gallery-left-nav {
  right: -46px !important;
  left: auto !important;
  top: auto !important;
  bottom: -16px !important;
  padding 0px !important;
}

.image-gallery-left-nav .image-gallery-svg {
  height: 38px !important;
  width: 38px !important;
}

.image-gallery-right-nav .image-gallery-svg {
  height: 38px !important;
  width: 38px !important;
}

.image-gallery-right-nav {
  right: -46px !important;
  left: auto !important;
  top: auto !important;
  bottom: 31px !important;
  padding 0px !important;
}

.image-gallery-svg {
  height: 33px !important;
  width: 28px !important;
}

.center div {
  width: 523px;
  height: 343px;
}

.center img {
  height: 100%;
  background: #000;
  border-radius: 5px;
}

.trails-gallary .image-gallery-play-button {
  right: auto !important;
  top: auto !important;
  left: auto !important;
  bottom: 0px !important;
  padding: 5px;
  width: 40px;
  height: 40px;
  padding: 9px 8px 7px;
  border-radius: 3px;
  background-color: rgba(14, 14, 14, 0.6);
  margin-left: 9.6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.trails-gallary .image-gallery-left-nav {
  right: 53px !important;
  left: auto !important;
  top: auto !important;
  bottom: -24px !important;
  padding: 0px !important;
  width: 40px;
  height: 40px;
  padding: 9px 8px 7px 0px;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  background-color: rgba(14, 14, 14, 0.6);
}

.trails-gallary .image-gallery-left-nav .image-gallery-svg {
  height: 38px !important;
  width: 38px !important;
}

.trails-gallary .image-gallery-right-nav .image-gallery-svg {
  height: 38px !important;
  width: 38px !important;
}

.trails-gallary .image-gallery-right-nav {
  right: 13px !important;
  left: auto !important;
  top: auto !important;
  bottom: -24px !important;
  padding: 0px !important;
  width: 40px;
  height: 40px;
  background-color: rgba(14, 14, 14, 0.6);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.trails-gallary .image-gallery-svg {
  height: 25px !important;
  width: 25px !important;
  stroke-linejoin: round;
}

.trails-gallary .center div {
  width: 100%;
  height: 100%;
}

.trails-gallary .center img {
  height: 100%;
  background: #313335;
  border-radius: 5px;
}

.trails-gallary .image-gallery-index {
  width: auto !important;
  height: auto !important;
}

`;
export default GlobalStyle;


export const PageLayout = styled.div`
  display: flex;
  background-color: #eff4f7;
  height: 1000px;
`;

export const Container = styled(Content) <{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '295px'};
  
  //height: auto;  
  //width: 700px;
  height: 100%;
  width: 100%;
  max-width: 700px;
  flex-direction: column;
  padding: 67px 50px 25px 45px;
  transition: 0.2s;
  //overflow: initial;
  /*& > div {
    max-width: auto;
  }*/
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  & > div > div > a {
    pointer-events: none;
  }
`;

export const Containers = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 69px 0 7px;
  width: 100%;
  height: 85px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: -7px;
`;

export const ContinueButton = styled(Button)`
  margin: 10px 0 0 10px;
  padding: 5px 20px;
  opacity: 0.5;
  height: 30px;
  margin: 10px 17px 13px 0;
  padding: 7px 20px;
`;

export const LeftButton = styled(Button)`
  margin: 0 17px 13px 0;
  white-space: nowrap;
`;

export const ComponentHeader = styled.div`
  width: 350px;
  height: 135px;
`;

export const SectionName = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-weight: 500;
  color: #767676;
`;

export const ApiAlertContainer = styled.div`
  width: 700px;
  height: auto;
  margin-bottom: 20px;
`;

export const ImageContainer = styled.div`
  display: flex;
  width: auto;
  height: auto;
  margin: 0 0 0.5px;
  border-radius: 5px;
  opacity: 0.4;
  object-fit: contain;
`;

export const SpinnerDesign = 'primary';

export const LineUIContainer = styled.div`
  width: 700px;
/*@media screen and (min-height: 800px) { 
  width: 700px;
}
@media screen and (max-height: 799px) { 
  width: 30vw;
}*/
`;

export const NoImage = styled.img`
  width: inherit;
  height: auto;
  border-radius: 5px;
  margin-top: 7px;
`;

export const SliderContainer = styled.div`
  margin-left: -5px;
  margin-right: -4px;
  margin-bottom: 9px;
  margin-top: 5px;
  & > div > div:nth-child(2) {
    margin-top: -20px !important;
  }
  & > div > div > label {
    margin-bottom: 0px !important;
  }
  & > div > div > label:first-child {
    font-size: 14px;
    color: #8b9196;
    font-weight: 500;
  }
  & > div > div > label:nth-child(2) {
    font-size: 12px;
    font-style: italic;
    color: rgba(120, 138, 144, 0.72);
  }
  & > div > div:nth-child(2) > div:first-child {
    background-image: linear-gradient(to bottom,hsl(210, 91.4%, 86.3%),hsl(203,42%,94%) 98%);
  }
  & > div > div:nth-child(2) > :nth-child(2) > :nth-last-child(3) {
    transform: translateX(-95%) !important;
  }
`;

export const BackButton = styled(Button)`
  margin: 0 9px 13px 0;
  white-space: nowrap;
`;

export const AlertContainer = styled.div`
  margin: 10px 0;
`;

export const ValidationAlert = styled(AlertBar)`
  margin: 10px 0;
`;

export const LineUiImageWrapper = styled.div<{ isScrollbar: boolean, height?: any }>`
  width: inherit;
  height: auto;
  border-radius: 5px;
  object-fit: contain;
  overflow: auto;
  //max-height: ${({ height }) => height && height + 'px'};
  width: ${({ isScrollbar }) => isScrollbar ? '715px' : '700px'};
  margin-bottom: 30px;

  text {
    fill: #000;
  }
`;

export const ImageText = styled.div`
  position: absolute;
  height: auto !important;
  width: auto !important;
  margin: 0 0 0 12px;
  padding: 6px 9px 5px 8px;
  border-radius: 3px;
  background-color: rgba(14, 14, 14, 0.6);
  z-index: 9;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #d5d9dc;
`;