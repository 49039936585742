import React, { useContext } from 'react';
import { URLPathContext } from '../App';

import styled, { css } from 'styled-components';
import { LogoMark, LogoText } from '../svg';


const Container = styled.div`
  border-right: 1px solid hsla(0, 0%, 84%, 50%);
  display: flex;
  flex-direction: column;
  position: fixed;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  transition: 0.3s;
  scrollbar-width: thin;
  box-shadow: inset 0 0 20px 0 rgba(178, 201, 217, 0.15);
  background-color: #eff4f7;
  z-index: 98;
`;

const InnerContainer = styled.span`
  display: contents;
`;

const Layout = styled.div`
  height: auto;
  width: 100%;
  margin-right: -280px;
`;

const LogoContainer = styled.div`
  border-bottom: 1px solid hsla(0, 0%, 84%, 50%);
  padding: 0 20px 17px 0;
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

const SidebarBox = styled.div<{ flex?: string }>`
  position: relative;
  padding: 23px 0 0px 20px;
  ${({ flex }) => flex && css`
    flex: ${flex};
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`}
`;


const LogoTextStyle = styled(LogoText)`
  margin: 16px 0 0 20px;
`;

const LogoMarkStyle = styled(LogoMark)`
  margin: 16px 0 0px 21px;
`;

const Section = styled.div<{ index: string, selected: string }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  :before {
    display: block;
    content: '';
    background-color: hsl(201, 77%, 71%);

    ${({ index, selected }) => {
    if (index === selected) {
      return css`
            width: 6px;
            border-radius: 5px;
            margin-right: 15px;
            height: 20px;
          `;
    } else {
      return css`
            height: 20px;
            border-radius: 5px;
            width: 6px;
            margin-right: 15px;
            opacity: 0.35;
            background-color: #d0dce2
          `;
    }
  }}
  }

  > *:last-child {
    margin-left: auto;
  }
`;

const SectionJob = styled.div<{ index: string, selected: string }>`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
  :before {
    display: block;
    content: '';
    background-color: hsl(201, 77%, 71%);

    ${({ index, selected }) => {
    if (index === selected
        || selected === '/analyze-step1'  || selected === '/analyze-step2'
        || selected === '/analyze-result' || selected === '/analyze-detail'
    ) {
      return css`
            width: 6px;
            border-radius: 5px;
            margin-right: 15px;
            height: 20px;
          `;
    } else {
      return css`
            height: 20px;
            border-radius: 5px;
            width: 6px;
            margin-right: 15px;
            opacity: 0.35;
            background-color: #d0dce2
          `;
    }
  }}
  }

  > *:last-child {
    margin-left: auto;
  }
`;

const SectionText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SectionTitle = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  color: #585858;
`;





const SectionLink: React.FC<{ index: string, selected: string, title: string }> = ({ index, selected, title }) => {
  const { setNowURL } = useContext(URLPathContext);
  return (
    <Section index={index} selected={selected} onClick={() => setNowURL(index)}>
      <SectionText>
        <SectionTitle>{title}</SectionTitle>
      </SectionText>
    </Section>
  );
};

const SectionLinkJob: React.FC<{ index: string, selected: string, title: string }> = ({ index, selected, title }) => {
  const { setNowURL } = useContext(URLPathContext);
  return (
    <SectionJob index={index} selected={selected} onClick={() => setNowURL(index)}>
      <SectionText>
        <SectionTitle>{title}</SectionTitle>
      </SectionText>
    </SectionJob>
  );
};


const Sidebar: React.FC<any> = () => {
  const { nowURL } = useContext(URLPathContext);
  return (
    <Container>
      <InnerContainer>
        <LogoContainer onClick={() => { window.location.href = '/'; }}>
          <LogoMarkStyle />
          <LogoTextStyle />
        </LogoContainer>        
        <Layout>
          <SidebarBox>
            <SectionLink index='/main' selected={nowURL} title='全体ダッシュボード' />
            <SectionLink index='/people-table' selected={nowURL} title='解析結果テーブル' />
            <SectionLinkJob index='/analyze-step1' selected={nowURL} title='詳細軌跡分析' />
            <SectionLink index='/filter-area' selected={nowURL} title='エリア設定' />
            <SectionLink index='/staff-area' selected={nowURL} title='スタッフエリア設定' />
          </SidebarBox>
        </Layout>
      </InnerContainer>
    </Container>
  );
};

export default Sidebar;
