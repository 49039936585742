import React, { useState, useCallback, useEffect, useContext } from 'react';
import useLocalStorage from '../../hooks/useLocalstorage';
import { URLPathContext } from '../../App';
import { saveFilterConfig } from '../../apis';

import styled from 'styled-components';
import { Content, SmallInput, PageHeader, Button } from 'scorer-ui-kit';
import { Buttons, HeaderContainer, BackButton, ValidationAlert } from '../../style';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { DatePicker } from 'antd';
import Stack from '@mui/material/Stack';
import dayjs from 'dayjs';

const Container = styled(Content) <{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '295px'};
  height: 100%;
  width: 100%;
  max-width: 1000px;
  flex-direction: column;
  padding: 67px 50px 25px 45px;
  transition: 0.2s;
`;

const InputContainer = styled.div`
  display: flex;
  width: inherit;
  margin: 5px 0 0 0;
  justify-content: space-between;
`;

const JobsListTitle = styled.div`
  display: flex;
  width: inherit;
  margin: 5px 0 0 0;
  justify-content: space-between;
  padding: 20px 0 0 0;
  border-top: 1px solid grey;
`;

const SelectFieldBox = styled.select`
  width: 160px;
  height: 30px;
  padding: 0 0 0 10px;
  border-radius: 3px;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  color: #767676;
  margin-top: 5px;
`;

const Position = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 0 10px 0;
`;

const SmallInputContainer = styled.div`
  display: flex;
  width: auto;
  margin: 0 30px 0 0;
`;

const SmallInputBox = styled(SmallInput)`
  width: 150px;
  padding: 0 14px 0 0;
  border-radius: 3px;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  color: #767676;
  margin-top: -5px;
`;

const InputHeader = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #7f7f7f;
  margin-bottom: 7px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
`;

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : '#dde7ed',
  display: 'flex',
  padding: '5px',
  overflow: 'auto',
});

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: '10px',
  margin: '5px',
  background: isDragging ? '#c0ffe9' : '#f0fffa',
  ...draggableStyle,
});

// tien duc
const ButtonArea = styled.div`
  margin-bottom: 30px;
  width: 100%;
`;

const { RangePicker } = DatePicker;


// Tien - 2023.10.24
const GateContainer = styled.div`
  display: flex;
  width: inherit;
  margin: 5px 0 0 0;
  justify-content: flex-start;       
`;




const AnalyzeStep2 = () => {
  const { setNowURL } = useContext(URLPathContext);
  const [validationAlert, setValidationAlert] = useState<{ key?: any, message: string, type: 'success' | 'error' | 'warning' | 'info' | undefined }>({ key: new Date().getTime(), message: '', type: undefined });
  const { floorPlanStorage, setFloorPlanStorage } = useLocalStorage();
  const [waitingPool, setWaitingPool] = useState([]);
  const [filterSets, setFilterSets] = useState(floorPlanStorage.filterSets.length!==0 ? floorPlanStorage.filterSets : [{'id':0, 'name':'filterSet0', 'filterStaff': 'ALL', 'filterList':[]}]);
  const [selectedFilterSet, setSelectedFilterSet] = useState(floorPlanStorage.selectedFilterSet ? floorPlanStorage.selectedFilterSet : 0);
  
  // tien duc
  const [datePicker, setDatePicker] = useState<any>(floorPlanStorage.datePicker_job ? floorPlanStorage.datePicker_job : [dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')]);
  
  // Tien - 2023.10.24
  const [selectedEntranceGate, setSelectedEntranceGate] = useState<any>({});
  const [selectedExitGate, setSelectedExitGate] = useState<any>({});
  const [gateAllData, setGateAllData] = useState<any>([]);

  useEffect(() => {
    setGateAllData(floorPlanStorage.gateInfo);
  },[floorPlanStorage.gateInfo]);


  //以前の分析結果をリセット
  useEffect(() => {
    setFloorPlanStorage({'analyze_result': []});
  }, [setFloorPlanStorage]);

  //関連待ちjobpoolを作成
  useEffect(() => {
    // tien duc
    // 移動時間追加
    for (let i = 0; i < filterSets[selectedFilterSet].filterList.length; i++) {
      if (!('next_time' in filterSets[selectedFilterSet].filterList[i])) {
        filterSets[selectedFilterSet].filterList[i].next_time = 50;
      }
      if (i === filterSets[selectedFilterSet].filterList.length - 1) {
        filterSets[selectedFilterSet].filterList[i].next_time = 0;
      }
    }


    //全job取得
    const filters = JSON.parse(JSON.stringify(floorPlanStorage.filters));
    //全関連済みjob ID取得
    const completed_pool = [];
    for (let k=0; k < filterSets[selectedFilterSet].filterList.length; k++) {
      completed_pool.push(filterSets[selectedFilterSet].filterList[k].id);
    }
    //差分取る
    const new_waitingPool = [];
    for (let i=0; i < filters.length; i++) {
      if(completed_pool.indexOf(filters[i].id) === -1)
        new_waitingPool.push(filters[i]);
    }
    setWaitingPool(new_waitingPool);
  }, [floorPlanStorage, filterSets, selectedFilterSet]);




  /*---------dnd---------*/
  // 同テーブル内の順位移動
  const reorderWaitingPool = useCallback((startIndex, endIndex) => {
    const new_waitingPool = [...waitingPool];
    const removed = new_waitingPool.splice(startIndex, 1)[0];
    new_waitingPool.splice(endIndex, 0, removed);
    setWaitingPool(new_waitingPool);
  }, [waitingPool]);

  const reorderFilterSetsPool = useCallback((startIndex, endIndex) => {
    const newfilterSets = [...filterSets];
    const removed = newfilterSets[selectedFilterSet].filterList.splice(startIndex, 1)[0];
    newfilterSets[selectedFilterSet].filterList.splice(endIndex, 0, removed);
    const lastIndexNumber = newfilterSets[selectedFilterSet].filterList.length - 1;
    
    // tien duc
    if (startIndex === lastIndexNumber) {
      removed.next_time = 50;
      newfilterSets[selectedFilterSet].filterList[
        lastIndexNumber
      ].next_time = 0;
    } else if (endIndex === lastIndexNumber) {
      removed.next_time = 0;
      if (
        newfilterSets[selectedFilterSet].filterList[lastIndexNumber - 1]
          .next_time === 0
      ) {
        newfilterSets[selectedFilterSet].filterList[
          lastIndexNumber - 1
        ].next_time = 50;
      }
    }

    setFilterSets(newfilterSets);
  }, [filterSets, selectedFilterSet]);

  // 関連付け
  const move = useCallback((source, destination) => {
    const new_waitingPool = [...waitingPool];
    const newfilterSets = [...filterSets];

    if (source.droppableId === 'waitingPool'){ // waitingPool → filterSetsPool, 関連付け
      let removed: any = [];
      if (new_waitingPool[source.index].type === 'repeatable'){
        removed = new_waitingPool.slice(source.index, source.index+1)[0];
      }else{
        removed = new_waitingPool.splice(source.index, 1)[0];
      }

      // tien duc
      if (
        destination.index === 0 &&
        newfilterSets[selectedFilterSet].filterList.length === 0
      ) {
        removed.next_time = 0;
      } else if (
        destination.index !==
        newfilterSets[selectedFilterSet].filterList.length
      ) {
        removed.next_time = 50;
      } else if (
        destination.index ===
        newfilterSets[selectedFilterSet].filterList.length
      ) {
        removed.next_time = 0;
        newfilterSets[selectedFilterSet].filterList[
          destination.index - 1
        ].next_time = 50;
      }

      newfilterSets[selectedFilterSet].filterList.splice(destination.index, 0, removed);
    }else{ // filterSetsPool → waitingPool, 関連外し
      const removed = newfilterSets[selectedFilterSet].filterList.splice(source.index, 1)[0];
      delete removed.next_time;  // tien duc
      new_waitingPool.splice(destination.index, 0, removed);
    }

    setWaitingPool(new_waitingPool);
    setFilterSets(newfilterSets);
  }, [waitingPool, filterSets, selectedFilterSet]);

  //dnd移動判定
  const onDragEnd = useCallback((result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    //同テーブル内の順位移動
    if (source.droppableId === destination.droppableId) {
      if (source.droppableId === 'waitingPool') {
        reorderWaitingPool(source.index, destination.index);
      } else {
        reorderFilterSetsPool(source.index, destination.index);
      }

    //関連付け
    } else {
      move(source, destination);
    }

  }, [reorderWaitingPool, reorderFilterSetsPool, move]);
  /*---------dnd---------*/

  /*---------filterSets---------*/
  const addFilterSet = useCallback(() => {
    const newFilterSet = {
      'id': filterSets.length,
      'name': '',
      'filterStaff': 'ALL',
      'filterList': []
    };
    // idに重複しているかどうかチェック.既存filterSetsを削除した場合、id名前重複してしまうことがあるため
    const idList = [];
    for(let i = 0; i < filterSets.length; i++) { // 既存filterSets id取得
      idList.push(filterSets[i].id);
    }
    for(let i = 0; i < idList.length; i++) {
      if (idList.indexOf(i) === -1){
        newFilterSet.id = i;
        break;
      }
    }

    newFilterSet.name = 'filterSet_'+newFilterSet.id;
    setSelectedFilterSet(filterSets.length);
    setFilterSets([...filterSets, newFilterSet]);
  }, [filterSets]);

  const delFilterSet = useCallback(() => {
    if (filterSets.length === 1) {
      return false;
    }
    const newfilterSets = [...filterSets];
    newfilterSets.splice(selectedFilterSet, 1);
    setFilterSets(newfilterSets);
    setSelectedFilterSet(0);
  }, [filterSets, selectedFilterSet]);

  const renameFilterSet = useCallback(({ target: { value } }) => {
    const newfilterSets = [...filterSets];
    newfilterSets[selectedFilterSet].name = value;
    setFilterSets(newfilterSets);
  }, [filterSets, selectedFilterSet]);

  const changeStaff = useCallback(({ target: { value } }) => {
    const newfilterSets = [...filterSets];
    newfilterSets[selectedFilterSet].filterStaff = value;
    setFilterSets(newfilterSets);
  }, [filterSets, selectedFilterSet]);

  const getselectedFilterSet = useCallback(({ target: { value } }) => {
    setSelectedFilterSet(value);
  }, [setSelectedFilterSet]);


  /*---------route---------*/
  const goback = useCallback(() => {
    setFloorPlanStorage({'filterSets': filterSets});
    setFloorPlanStorage({'selectedFilterSet': selectedFilterSet});
    setNowURL('/analyze-step1');
  }, [filterSets, selectedFilterSet, setFloorPlanStorage, setNowURL]);

  const gonext = useCallback(() => {
    for (let i=0; i < filterSets.length; i++) {
      if (filterSets[i].filterList.length === 0){
        setValidationAlert({ key: new Date().getTime(), message: '順番設定されていない空白の設定があります。空白の設定を削除してください。', type: 'error' });
        return;
      }
    }
    saveFilterConfig({
      'filters': floorPlanStorage.filters,
      'filterSets': filterSets,
    })
      .then(() => {
        setFloorPlanStorage({'filterSets': filterSets});
        setFloorPlanStorage({'selectedFilterSet': selectedFilterSet});
        setFloorPlanStorage({'datePicker_job': datePicker});  // tien duc
        setFloorPlanStorage({'selectedGate': {selectedEntranceGate, selectedExitGate} });   // Tien - 2023.11.01
        setNowURL('/analyze-result');
      })
      .catch((error) => {
        setValidationAlert({ key: new Date().getTime(), message: '設定保存失敗しました'+error, type: 'error'});
      });

  }, [filterSets, selectedFilterSet, floorPlanStorage, setFloorPlanStorage, setNowURL, datePicker, selectedEntranceGate, selectedExitGate]);
  /*---------route---------*/

  //tien duc
  const handleNextTime = useCallback((e) => {
    const newfilterSets = [...filterSets];
    const { id, value } = e.target;
    newfilterSets[selectedFilterSet].filterList[id].next_time =
      parseInt(value);
    setFilterSets(newfilterSets);
  }, [filterSets, selectedFilterSet]);
  
  // 日付指定
  const handleChangeDateTime = useCallback((dateRange: any) => {
    let startDate = '';  // letしないとまれに操作ミスでエラーになることがある
    let endDate = '';
    if (dateRange){
      startDate = dayjs(dateRange[0].$d).format('YYYY-MM-DD HH:mm:ss');
      endDate = dayjs(dateRange[1].$d).format('YYYY-MM-DD HH:mm:ss');
      setDatePicker([startDate, endDate]);
      setFloorPlanStorage({'datePicker_job': [startDate, endDate]});
    }
  }, [setFloorPlanStorage]);

    
  // 入口・出口 // Tien - 2023.10.24    
  const handleChangeEntranceGate = useCallback(({ target: { value } }) => {
    setSelectedEntranceGate(value);
  }, [setSelectedEntranceGate]);

  const handleChangeExitGate = useCallback(({ target: { value } }) => {
    setSelectedExitGate(value);
  }, [setSelectedExitGate]);



  return (
    <Container>
      {validationAlert.message !== '' &&
        <ValidationAlert type={validationAlert.type} message={validationAlert.message} />}

      <HeaderContainer>
        <PageHeader title='対象人物抽出設定：詳細順番設定' />
        <Buttons>
          <BackButton size='small' design='secondary' onClick={goback}>戻る</BackButton>
          <Button design='primary' size='small' onClick={gonext}>分析結果</Button>
        </Buttons>
      </HeaderContainer>

      {/* tien duc */}
      <ButtonArea>
        <Stack direction='row' spacing={2}>
          <RangePicker
            showTime
            allowClear={false}
            defaultValue={[dayjs(datePicker[0]), dayjs(datePicker[1])]}
            onChange={handleChangeDateTime}
            placeholder={['ジョブ集計開始時刻', 'ジョブ集計終了時刻']}
          />
        </Stack>
      </ButtonArea>

      <InputContainer>
        <Position>
          <InputHeader>設定追加</InputHeader>
          <SmallInputContainer>
            <InputField>
              <Button design='primary' size='small' onClick={addFilterSet}>追加</Button>
            </InputField>
          </SmallInputContainer>
        </Position>
        <Position>
          <InputHeader>分析する設定を選択</InputHeader>
          <SmallInputContainer>
            <SelectFieldBox value={selectedFilterSet} onChange={(event) => getselectedFilterSet(event)}>
              {filterSets.map((item, i)=>{
                return <option key={'filterset_name_'+item.id} value={i}>{item.name}</option>;
              })}
            </SelectFieldBox>
          </SmallInputContainer>
        </Position>
        <Position>
          <InputHeader>設定名編集</InputHeader>
          <SmallInputContainer>
            <InputField>
              <SmallInputBox label='' value={filterSets[selectedFilterSet].name} onChange={(event) => renameFilterSet(event)} />
            </InputField>
          </SmallInputContainer>
        </Position>
        <Position>
          <InputHeader>スタッフ表示設定</InputHeader>
          <SmallInputContainer>
            <SelectFieldBox value={filterSets[selectedFilterSet].filterStaff} onChange={(event) => changeStaff(event)}>
              <option value='ALL'>全員表示</option>;
              <option value='NoStaff'>スタッフ非表示</option>;
              <option value='OnlyStaff'>スタッフのみ表示</option>;
            </SelectFieldBox>
          </SmallInputContainer>
        </Position>
        <Position>
          <InputHeader>設定削除</InputHeader>
          <SmallInputContainer>
            <Button size='small' design='primary' onClick={delFilterSet}>削除</Button>
          </SmallInputContainer>
        </Position>
      </InputContainer>

      <DragDropContext onDragEnd={onDragEnd}>
        <InputContainer>
          <Position>
            <InputHeader>順番設定済み</InputHeader>
          </Position>
        </InputContainer>
        <Droppable droppableId='taskJob' direction='horizontal'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {filterSets[selectedFilterSet].filterList.map((item, index) => {
                return (
                  <Draggable key={`taskJob-${item.id}`} draggableId={`taskJob-${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <hr />
        <JobsListTitle>
          <Position>
            <InputHeader>順番設定待ち</InputHeader>
          </Position>
        </JobsListTitle>
        <Droppable droppableId='waitingPool' direction='horizontal'>
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {waitingPool.map((item, index) => {
                return (
                  <Draggable key={`waitingPool-${item.id}`} draggableId={`waitingPool-${item.id}`} index={index}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* tien duc */}
      <div>
        <br />
        <hr />
        <br />
        {filterSets[selectedFilterSet].filterList.map((val, index) => {
          let nextVal = { name: '' };
          if (index < filterSets[selectedFilterSet].filterList.length - 1) {
            nextVal = filterSets[selectedFilterSet].filterList[index + 1];
          }

          return (
            <div key={index}>
              {val.next_time !== 0 ? (
                <div key={val.id}>
                
                  <InputHeader>
                    {val.name}から
                    {nextVal.name}
                    への移動時間
                  </InputHeader>
                  <SmallInputContainer>
                    <InputField>
                      <SmallInputBox
                        label=''
                        value={val.next_time || ''}
                        id={index}
                        onChange={handleNextTime}
                      />
                    </InputField>
                  </SmallInputContainer>
                </div>
              ) : null}
            </div>
          );
        })}
      </div>

      {/* Tien - 2023.11.08 */}
      <hr />
      <GateContainer>
        <Position>
          <InputHeader>入口指定</InputHeader>
          <SmallInputContainer>
            <SelectFieldBox onChange={(event) => handleChangeEntranceGate(event)}>
              <option value='' disabled selected>入口を選択ください</option>
              {gateAllData.filter((item) => item.type === 1).map((item)=>{
                return <option key={item.id} value={item.camera}>入口{item.camera}</option>;
              })}
            </SelectFieldBox>
          </SmallInputContainer>
        </Position>
        <Position>
          <InputHeader>出口指定</InputHeader>
          <SmallInputContainer>
            <SelectFieldBox onChange={(event) => handleChangeExitGate(event)}>
              <option value='' disabled selected>出口を選択ください</option>
              {gateAllData.filter((item) => item.type === 4).map((item)=>{
                return <option key={item.id} value={item.camera}>出口{item.camera}</option>;
              })}
            </SelectFieldBox>
          </SmallInputContainer>
        </Position>
      </GateContainer>
    </Container>
  );
};

export default AnalyzeStep2;