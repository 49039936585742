import React, { useState, useCallback, useEffect } from 'react';
// import { URLPathContext } from '../App';
import { useTranslation } from 'react-i18next';
import { Container, HeaderContainer } from '../style';
import { TypeTable, PageHeader, /*SelectField,*/ Button } from 'scorer-ui-kit';
import { getPeopleData } from '../apis';
import { Pagination } from 'antd';
import type { PaginationProps } from 'antd';
import styled from 'styled-components';
import DateInput from '../components/DateInput';
// import { dateFormat } from '../utils/index';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';

import { convertToTimeZone } from 'date-fns-timezone';

const dateFormat = (strDate: Date) => {
  try {
    const zonedTargetDate = convertToTimeZone(strDate, { timeZone: 'Asia/Tokyo' });
    return zonedTargetDate;
  } catch (err) {
    console.error(err);
  }
  return new Date();
};

const DATE_FORMAT_WITH_SEC = 'yyyy-MM-dd HH:mm:ss';

interface IInterval {
  start: Date,
  end: Date
}

const Buttons = styled.div`
  margin-top: 30px;
`;
const ContainerTable = styled(Container)`
  max-width: 100%;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 0 20px 2px;
`;

// const LabelText = styled.div`
//   font-size: 14px;
//   width: 200px;
//   font-weight: 500;
//   color: rgba(120, 138, 144, 0.72);
//   margin: 0 25px 0 0;
//   font-family: ${({ theme }) => theme.fontFamily.ui};
//   padding: 0;
//   line-height: 1.4;
// `;

// const TableContainer = styled.div`
//   table, th, td {
//     border: 1px solid black;
//     border-collapse: collapse;
//   }
//   th, td {
//     padding: 5px;
//   }
//   th {
//     text-align: left;
//   }
//   margin: 60px 0px;
// `;


const StyledTypeTable = styled.div`
  & > div > div > div > div > div > img {
    object-position: 0% 0%; /* X then Y */
  }
`;

const PeopleTable = () => {
  // const { setNowURL } = useContext(URLPathContext);
  const { t } = useTranslation(['CommonDict']);
  const [data, setData] = useState([]);
  const [pageContent, setPageContent] = useState([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);

  // const [type, setType] = useState('');

  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);

  const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`;

  const startDate = dateFormat(new Date());
  startDate.setDate(startDate.getDate() - 1);
  const [defaultDate,] = useState<IInterval>({ start: startDate, end: dateFormat(new Date()) });

  const [initialStartDate, setInitialStartDate] = useState<Date>(defaultDate.start);
  const [initialEndDate, setInitialEndDate] = useState<Date>(defaultDate.end);

  const updateStartDate = useCallback((date) => {
    if (initialEndDate.getTime() - 60000 <= date.getTime() || date > dateFormat(new Date())) {
      // setAlert({
      //   key: new Date(),
      //   message: t('Please select \'From\' date smaller then now date and \'To\' date. '),
      //   type: 'error'
      // });
      return;
    }
    setInitialStartDate(date);
  }, [initialEndDate]);

  const updateEndDate = useCallback((date) => {
    if (initialStartDate >= date || date > dateFormat(new Date())) {
      // setAlert({
      //   key: new Date(),
      //   message: t('Please select \'To\' date smaller then now and greater then \'From\' date. '),
      //   type: 'error'
      // });
      return;
    }
    setInitialEndDate(date);
  }, [initialStartDate]);

  const onQuerryTbl = useCallback(async () => {
    setTableLoading(true);
    try {      
      const response = await getPeopleData({ /*analysis_type: type,*/ date_range: {start_date: format(initialStartDate, DATE_FORMAT_WITH_SEC), end_date: format(initialEndDate, DATE_FORMAT_WITH_SEC)}});
      if(response.status === 200) {
        setData(response.data);
      } else {
        setData([]);
      }
      
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
      console.error((error as Error).message);
    }
  }, [/*type,*/ initialStartDate, initialEndDate]);



  const handleTableData = useCallback(() => {
    const arrData = [];
    const copiedObj = [...data].slice((page - 1) * pageSize, page * pageSize);
    
    if(data.length > 0){
      for (let i = 0; i < copiedObj.length; i++) {
        arrData.push({
          id: i,
          header: {
            image: 'data:image/jpeg;base64,'+copiedObj[i].body_image,
            mediaUrl: 'data:image/jpeg;base64,'+copiedObj[i].body_image,
            mediaType: 'img',
          },
          columns:[
            { text: copiedObj[i].person_id },
            { text: copiedObj[i].zone_name },
            { text: copiedObj[i].age === 0 ? 'データなし' : copiedObj[i].age },
            { text: copiedObj[i].gender === 0 ? 'データなし' : copiedObj[i].gender === 1 ? '男性' : '女性' },
            { text: copiedObj[i].start_time },
            { text: copiedObj[i].end_time },
            { text: copiedObj[i].duration },
            { customComponent: 
  <Link 
    to={{ 
      pathname: '/table-details', 
      // search: `?pid=${copiedObj[i].person_id}&startDate=${format(initialStartDate, DATE_FORMAT_WITH_SEC)}&endDate=${format(initialEndDate, DATE_FORMAT_WITH_SEC)}`
      search: `?pid=${copiedObj[i].person_id}`
    }} target='_blank' replace
  >[行動履歴]
  </Link>}
          ]
        });
      }
      setPageContent(arrData);
    } else {
      arrData.push({
        id: 0,
        header: { image: '', mediaUrl: '', mediaType: 'img' },
        columns:
          [
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' }
          ]
      });
      setPageContent(arrData);
    }
    
  
  },[data, page, pageSize/*, initialStartDate, initialEndDate*/]);


  useEffect(() => {
    handleTableData();
  }, [handleTableData]);

  const columnConfig: any=[
    {
      cellStyle: 'normalImportance',
      header: 'id',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: 'カメラ名',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '年齢',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '性別',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '開始時間',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '終了時間',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '滞在時間',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '詳細情報',
      alignment: 'center'
    }
  ];
    
  

  return (
    <ContainerTable>
      <HeaderContainer>
        <PageHeader title='解析結果テーブル' />
      </HeaderContainer>
      <FlexColumn>
        {/* <LabelText>{t('日付フィルター')}</LabelText> */}
        <FlexColumn>
          <DateInput labelText={t('から')} date={initialStartDate} callBackFunction={updateStartDate} />
          <DateInput labelText={t('まで')} date={initialEndDate} callBackFunction={updateEndDate} />
          
          <Buttons>
            <Button design='primary' onClick={onQuerryTbl} size='small'>検索</Button>
          </Buttons>
        </FlexColumn>
      </FlexColumn>
      {/* <FlexColumn>
        <LabelText>{t('タイプ')}</LabelText>
        <SelectField
          changeCallback={(e)=>setType(e)}
          placeholder='選択してください'
          value={type}
        >
          <option value='Body'>
            Body
          </option>
          <option value='Face'>
            Face
          </option>
        </SelectField>
      </FlexColumn> */}
      

      <StyledTypeTable>
        <TypeTable
          hasThumbnail
          columnConfig={columnConfig}
          emptyTableText='There is currently no data'
          emptyTableTitle='No Data Available'
          isLoading={tableLoading}
          loadingText='Loading Data..'
          rows={pageContent}
        />
      </StyledTypeTable>
      {data.length > 0 && 
        <Pagination
          current={page}
          size='small'
          pageSize={pageSize}
          onChange={(newPage, newPageSize) => {
            setPageSize(newPageSize);
            setPage(pageSize !== newPageSize ? 1 : newPage);
          }}
          showSizeChanger
          pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000]}
          total={data.length}
          showTotal={showTotal}
          style={{ marginTop: '18px', textAlign: 'center'}}
        />}



    </ContainerTable>
  );
};

export default PeopleTable;
