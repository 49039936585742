import * as React from "react";

const SvgRetryJob = (props: ISvgIcons) => {
  return (
    <svg
      width={props.size}
      height={props.size}
      xmlns="http://www.w3.org/2000/svg"
      viewBox={"0 0 24 24"}
      {...props}
    >
      <g
        stroke={props.color}
        strokeWidth={props.weight}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
        vectorEffect={"non-scaling-stroke"}
      >
        <path d="M15 18.75h-3.75v3.75" />
        <path d="M22.667 19.483a5.572 5.572 0 0 1-10.74-.733M19.5 15.75h3.75V12" />
        <path d="M11.833 15.017a5.572 5.572 0 0 1 10.74.733M8.25 14.25a4.5 4.5 0 1 1 4.25-5.978" />
        <path d="M9.75 9.75h-1.5v-1.5" />
        <path d="M7.5 20.25H2.25a1.5 1.5 0 0 1-1.5-1.5V2.25a1.5 1.5 0 0 1 1.5-1.5h10.629a1.5 1.5 0 0 1 1.06.44l2.872 2.871a1.5 1.5 0 0 1 .439 1.061V7.5" />
      </g>
    </svg>
  );
};

export default SvgRetryJob;
