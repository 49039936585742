import React, { useState, useEffect, useCallback, useContext, /* useLayoutEffect,*/ } from 'react';
import useLocalStorage from '../hooks/useLocalstorage';
import { URLPathContext } from '../App';
import { getHeatmap/*, getGraph*/, gateAllData } from '../apis';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import { Content } from '../components/Layout';
import styled from 'styled-components';
import { HeaderContainer } from '../style';
import { PageHeader } from 'scorer-ui-kit';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Box from '@mui/joy/Box';
import Stack from '@mui/material/Stack';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { DatePicker } from 'antd';

//import { svgToPng } from '@iwstkhr/svg-to-png';
//import svg64 from 'svg64';
//import domtoimage from 'dom-to-image';
//import html2canvas from 'html2canvas';

import dayjs from 'dayjs';
import h337 from 'heatmap.js';
import Plotly from 'plotly.js';
import createPlotlyComponent from 'react-plotly.js/factory';
const Plot = createPlotlyComponent(Plotly);


const Container = styled(Content) <{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '295px'};
  height: 100%;
  width: 100%;
  //max-width: 1500px;
  flex-direction: column;
  //padding: 60px 50px 25px 50px;
  transition: 0.2s;
`;

const HeatmapSVG = styled.svg`
  //display: flex;
  //objectFit: contain;
  width: 1080px;
  height: 720px;
`;

const ZoomContainer = styled.div`
  border: 1px solid;
  //overflow: hidden;
  //width: 1080px;
  //height: 720px;
  //overflow: none;
`;

const SelectFieldBox = styled.select`
  width: 160px;
  height: 30px;
  border-radius: 5px;
  outline: none;
  font-size: 14px;
  color: #767676;
  margin-left: 0px;
  margin-right: 10px;
  border-color: #dfe1e2;
`;

const StyledButton = styled(Button)`
  background-image: linear-gradient(135.00deg,hsla(200,77.5%,68.6%,1.000) 0%,hsla(207,80.2%,64.3%,1.000) 100%);
  height: 30px;
  padding: 0 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  border-radius: 3px;
`;


//make line smooth
function curvingLines(points: any) {
  const lineProperties = (pointA: any, pointB: any) => {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    };
  };

  const controlPointCalc = (
    current: any,
    previous: any,
    next: any,
    reverse: any
  ) => {
    const c = current;
    const p = previous ? previous : c;
    const n = next ? next : c;
    const smoothing = 0.2;
    const o = lineProperties(p, n);
    const rev = reverse ? Math.PI : 0;

    const x = c[0] + Math.cos(o.angle + rev) * o.length * smoothing;
    const y = c[1] + Math.sin(o.angle + rev) * o.length * smoothing;

    return [x, y];
  };

  const point = [];
  for(let i=0; i<points.length; i++) {
    if (i > 0) {
      const cp1 = controlPointCalc(points[i - 1], points[i - 2], points[i], false);
      const cp2 = controlPointCalc(points[i], points[i - 1], points[i+1], true);
      point.push([cp1, cp2, points[i]]);
    }
    else{
      point.push([points[i]]);
    }
  }
  return point;
}


const Main = () => {
  const { RangePicker } = DatePicker;
  const { setNowURL } = useContext(URLPathContext);
  const {floorPlanStorage, setFloorPlanStorage} = useLocalStorage();
  const [loading, setLoading] = useState(false);

  const [showHeatmap, setShowHeatmap] = useState(0);
  const [graphOption, setGraphOption] = useState('day');

  const [graphData, setGraphData] = useState(floorPlanStorage.graphData ? floorPlanStorage.graphData : {day:{number_of_people: [], time_list: []}, hour:{number_of_people: [], time_list: []}});
  const [heatmapData, setHeatmapData] = useState(floorPlanStorage.heatmapData ? floorPlanStorage.heatmapData : [[],[]]);

  const mapImg = floorPlanStorage.original_image ? 'data:image/jpeg;base64,'+floorPlanStorage.original_image : '';
  const [mapWitdh, setMapWitdh] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);
  
  const [withStaff, setWithStaff] = useState(floorPlanStorage.selectedStaffType_main ? floorPlanStorage.selectedStaffType_main : 'ALL');
  const [analyzeType, setAnalyzeType] = useState(floorPlanStorage.selectedMainAnalyzeType ? floorPlanStorage.selectedMainAnalyzeType : 'Body');
  const [datePicker, setDatePicker] = useState<any>(floorPlanStorage.datePicker_main ? floorPlanStorage.datePicker_main : [dayjs().subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss'), dayjs().format('YYYY-MM-DD HH:mm:ss')]);
    
    
  // Tien - 2023.11.01
  const [gateData, setGateData] = useState<any>([]);

  const fetchAllGateData = useCallback(async () => {
    setLoading(true);
    try {      
      const res = await gateAllData();
      setGateData(res.data);
      setFloorPlanStorage({ 'gateInfo': res.data});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error((error as Error).message);
    }
  }, [setFloorPlanStorage]);

  useEffect(() => {
    fetchAllGateData();
  }, [fetchAllGateData]);

  // new db no data
  useEffect(() => {
    floorPlanStorage.filterAreas.length === 0 ? setNowURL('/filter-area')
      : floorPlanStorage.staffAreas.length === 0 && setNowURL('/staff-area');
  }, [floorPlanStorage.filterAreas, floorPlanStorage.staffAreas, setNowURL]);

  // get bg image size
  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    };
    loadImage(mapImg)
      .then((res: { width: number; height: number }) => {
        setMapWitdh(res.width);
        setMapHeight(res.height);
      })
      .catch(e => console.error('onload error', e));
  }, [mapImg]);
  
  // heatmap creater
  const handleHeatmap = useCallback((heatmap_data, trail_data) => {
    const elem: HTMLElement = document.getElementById('heatmap')!;
    const canvas = document.getElementsByTagName('canvas')[0];
    canvas?.remove();
    if (elem) {
      const heatmapInstance = h337.create({
        container: elem,
        radius: 75,
        blur: 0.9,
      });

      // now generate some random data
      const points = [];
      const revise_w = 1080/mapWitdh;
      const revise_h = 720/mapHeight;
      for (let i = 0; i < heatmap_data.length; i++) {
        const point = {
          x: Math.trunc(heatmap_data[i][0] * revise_w),
          y: Math.trunc(heatmap_data[i][1] * revise_h),
          value: 3,
        };
        points.push(point);
      }

      const data = {
        max: heatmap_data.length,
        min: 0,
        data: points,
      };
      heatmapInstance.setData(data);
      
      const trail_canvas = document.getElementsByTagName('canvas')[0];
      const context = trail_canvas.getContext('2d');

      // 軌跡
      for(let i=0; i<trail_data.length; i++) {
        const point = curvingLines(trail_data[i][0]);
        context.beginPath();
        context.lineWidth = 2;
        context.strokeStyle = trail_data[i][1];
        context.moveTo(
          point[0][0][0] * revise_w,
          point[0][0][1] * revise_h
        );
        for(let k=1; k<point.length; k++) {
          const cp1 = point[k][0];
          const cp2 = point[k][1];
          const p = point[k][2];
          context.bezierCurveTo(
            cp1[0] * revise_w, cp1[1] * revise_h,
            cp2[0] * revise_w, cp2[1] * revise_h,
            p[0]   * revise_w, p[1]   * revise_h
          );
        }
        context.stroke();
      }
      
      // signagePosition
      const signagePosition = floorPlanStorage.signagePosition.map((item: any) => item.signage_line_points.points);
      /*const signagePosition = [
        [{x: 660, y: 330}, {x: 660, y: 130}],
        [{x: 662, y: 329}, {x: 661, y: 132}],
        [{x: 241, y: 287}, {x: 410, y: 288}],
        [{x: 240, y: 290}, {x: 400, y: 288}],
        [{x: 50, y: 50}, {x: 100, y: 50}],
        [{x: 200, y: 150}, {x: 150, y: 150}],
        [{x: 100, y: 100}, {x: 200, y: 200}],
        [{x: 400, y: 400}, {x: 300, y: 300}],
      ];
      console.log(signagePosition);*/
      context.shadowColor = 'gray';
      context.shadowBlur = 10;
      context.shadowOffsetX = 5;
      context.shadowOffsetY = 5;
      for (let i = 0; i < signagePosition.length; i++) {
        const start_x = signagePosition[i][0].x * revise_w;
        const start_y = signagePosition[i][0].y * revise_h;
        const end_x = signagePosition[i][1].x * revise_w;
        const end_y = signagePosition[i][1].y * revise_h;
        // まず線を描く
        context.strokeStyle = 'rgb(100, 100, 255)';
        context.lineWidth = 3;
        context.beginPath();
        context.moveTo(start_x, start_y);
        context.lineTo(end_x, end_y);
        context.stroke();
      
        // 次はサイネージの正面を表す
        const triHeight = 10; // 三角形的大小
        const offset = 20; // 三角形离线的距离
        const angle = Math.atan2(end_y - start_y, end_x - start_x);
        const midX = (start_x + end_x) / 2;
        const midY = (start_y + end_y) / 2;

        // 判断三角形的朝向
        const direction = angle - Math.PI / 2;
        const triMidX = midX + offset * Math.cos(direction);  // 三角形的中心点
        const triMidY = midY + offset * Math.sin(direction);

        // 绘制三角形
        context.fillStyle = 'rgb(100, 100, 255)';
        context.beginPath();
        context.moveTo(triMidX + triHeight * Math.cos(direction), triMidY + triHeight * Math.sin(direction));
        context.lineTo(triMidX + triHeight * Math.cos(direction + 2 / 3 * Math.PI), triMidY + triHeight * Math.sin(direction + 2 / 3 * Math.PI));
        context.lineTo(triMidX + triHeight * Math.cos(direction + 4 / 3 * Math.PI), triMidY + triHeight * Math.sin(direction + 4 / 3 * Math.PI));
        context.closePath();
        context.fill();
        context.stroke();
      }
        
      // 入口・出口  
      context.font = 'bold 24px Arial';
      gateData.forEach(function(gate) {
        if (gate.type === 1) {
          context.fillStyle = 'lightgreen';
          context.lineWidth = 2;
          const textWidth = context.measureText('入口');
          context.fillRect(
            gate.cam_pos.x * revise_w,
            gate.cam_pos.y * revise_h - textWidth.actualBoundingBoxAscent,
            textWidth.width,
            textWidth.actualBoundingBoxAscent + textWidth.actualBoundingBoxDescent);
          context.fillStyle = 'white';
          context.fillText('入口', gate.cam_pos.x * revise_w, gate.cam_pos.y * revise_h);
        } else if (gate.type === 4) {
          context.fillStyle = 'red';
          context.lineWidth = 2;
          const textWidth = context.measureText('出口');
          context.fillRect(gate.cam_pos.x * revise_w, gate.cam_pos.y * revise_h - textWidth.actualBoundingBoxAscent, textWidth.width, textWidth.actualBoundingBoxAscent + textWidth.actualBoundingBoxDescent);
          context.fillStyle = 'white';
          context.fillText('出口', gate.cam_pos.x * revise_w, gate.cam_pos.y * revise_h);
        }
      });
    }
  }, [mapWitdh, mapHeight, floorPlanStorage.signagePosition, gateData]);

  // get heatmap data
  const getData = useCallback(() => {
    const start = performance.now();
    setLoading(true);
    getHeatmap({
      'startDate': datePicker[0],
      'endDate': datePicker[1],
      'withStaff': withStaff,
      'staffAreas': floorPlanStorage.staffAreas,
      'analyzeType': analyzeType,
    })
      .then((res) => {
        if (res.data.StatusCode !== 400){
          const elapsed_start = performance.now();

          const file_path = res.data.file_path;
          fetch(file_path)
            .then(response => {
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              return response.json();
            })
            .then(data => {
              setGraphData(data.graph_data);
              setHeatmapData([data.heatmap_data, data.trail_data]);
              setFloorPlanStorage(prevState => ({
                ...prevState,
                graphData: data.graph_data,
                heatmapData: [data.heatmap_data, data.trail_data]
              }));
              
              // debug time
              const react_elapsed_time = (performance.now() - elapsed_start) / 1000;
              const react_total_time = (performance.now() - start) / 1000;

              const elapsed_time_select_db = data.elapsed_time_select_db;
              const elapsed_time_handle_data = data.elapsed_time_handle_data;
              const elapsed_time_heat_trail = data.elapsed_time_heat_trail;
              const elapsed_time_select_db_graph = data.elapsed_time_select_db_graph;
              const elapsed_time_graph_data = data.elapsed_time_graph_data;
              const api_total_time = data.total_time;
              
              console.log('—————————————————DEBUG INFO——————————————————');
              console.log(`heatmapと軌跡データを取得するために、${react_total_time.toFixed(2)}秒掛かりました`);
              console.log('----UI部分の詳細時間----');
              console.log(`総処理時間： ${react_total_time.toFixed(2)}`);
              console.log(`データ処理時間： ${react_elapsed_time.toFixed(2)}`);
              console.log(`apiからのデータ待ち時間： ${((elapsed_start-start)/1000).toFixed(2)}`);
              console.log(`uiとapiのデータ転送時間： ${((elapsed_start-start)/1000-api_total_time).toFixed(2)}`);
              console.log('********************');
              console.log('----apiの詳細時間----');
              console.log(`総処理時間： ${api_total_time.toFixed(2)}`);
              console.log(`DB検索時間： ${elapsed_time_select_db.toFixed(2)}`);
              console.log(`条件満たした人物を算出する時間： ${elapsed_time_handle_data.toFixed(2)}`);
              console.log(`heatmapと軌跡データを算出する時間： ${elapsed_time_heat_trail.toFixed(2)}`);
              console.log(`グラフ用のDB検索時間： ${elapsed_time_select_db_graph.toFixed(2)}`);
              console.log(`グラフデータの処理時間： ${elapsed_time_graph_data.toFixed(2)}`);
              console.log('********************');
              setLoading(false);
            }).catch((error) => {console.error(error); setLoading(false);});
        }
      })
      .catch((error) => {console.error(error); setLoading(false);});
  }, [floorPlanStorage.staffAreas, setFloorPlanStorage, analyzeType, datePicker, withStaff]);

  useEffect(() => {
    handleHeatmap(heatmapData[0], heatmapData[1]);
  },[heatmapData, handleHeatmap]);

  // スタッフ表示選択
  const handleStaffSet = useCallback((event: any) => {
    setLoading(false);
    setWithStaff(event.target.value);
    setFloorPlanStorage({'selectedStaffType_main': event.target.value});
  }, [setFloorPlanStorage]);

  // analyzeタイプ選択
  const handleAnalyzeType = useCallback((event: any) => {
    setLoading(false);
    setAnalyzeType(event.target.value);
    setFloorPlanStorage({'selectedMainAnalyzeType': event.target.value});
  }, [setFloorPlanStorage]);

  // 期間選択
  const handleDate = useCallback((dateRange: any) => {
    let startDate = '';  // letしないとまれに操作ミスでエラーになることがある
    let endDate = '';
    if (dateRange){
      setLoading(false);
      startDate = dayjs(dateRange[0].$d).format('YYYY-MM-DD HH:mm:ss');
      endDate = dayjs(dateRange[1].$d).format('YYYY-MM-DD HH:mm:ss');
      setDatePicker([startDate, endDate]);
      setFloorPlanStorage({'datePicker_main': [startDate, endDate]});
    }
  }, [setFloorPlanStorage]);


  return (
    <Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <HeaderContainer>
        <PageHeader title='全体ダッシュボード' />
      </HeaderContainer>

      <Stack spacing={2}>
        <Box>
          <SelectFieldBox defaultValue={analyzeType} onChange={(event) => handleAnalyzeType(event)}>
            <option value='Body'>解析方法：Body</option>
            <option value='Face'>解析方法：Face</option>
          </SelectFieldBox>
          <SelectFieldBox defaultValue={withStaff} onChange={(event) => handleStaffSet(event)}>
            <option value='ALL'>全員表示</option>
            <option value='NoStaff'>スタッフ非表示</option>
            <option value='OnlyStaff'>スタッフのみ表示</option>
          </SelectFieldBox>
          <RangePicker 
            showTime
            allowClear={false}
            onChange={handleDate}
            placeholder={['開始時間', '終了時間']}
            defaultValue={[dayjs(datePicker[0]), dayjs(datePicker[1])]}
          />
          <SelectFieldBox value={showHeatmap} onChange={(event) => setShowHeatmap(Number(event.target.value))}>
            <option value={0}>画像表示</option>
            <option value={1}>グラフ表示</option>
          </SelectFieldBox>
          {showHeatmap !== 0 ?
            <SelectFieldBox defaultValue={graphOption} onChange={(event) => setGraphOption(event.target.value)}>
              <option value='day'>日別平均滞在時間</option>
              <option value='hour'>時間別平均滞在時間</option>
            </SelectFieldBox>
            : <StyledButton variant='contained' onClick={getData}>検索</StyledButton>}
        </Box>

        {/* map zoom表示 */}
        <Stack sx={{ display: showHeatmap === 0 ? 'block' : 'none' }}>
          <TransformWrapper initialScale={1}>
            {({ zoomIn, zoomOut, resetTransform,/* ...rest*/ }) => (
              <Stack direction='row' spacing={2}>
                <Stack>
                  <Button variant='outlined' startIcon={<ZoomInIcon />} onClick={() => zoomIn()}>
                    拡大
                  </Button>
                  <Button variant='outlined' startIcon={<ZoomOutIcon />} onClick={() => zoomOut()}>
                    縮小
                  </Button>
                  <Button variant='outlined' onClick={() => resetTransform()}>
                    復元
                  </Button>
                </Stack>
                <ZoomContainer>
                  <TransformComponent>
                    <div className='heatmap' id='heatmap'>
                      <HeatmapSVG viewBox={'0 0 '+mapWitdh+' '+mapHeight} id='mapSVG'>
                        <image href={mapImg} />
                      </HeatmapSVG>
                    </div>
                  </TransformComponent>
                </ZoomContainer>
              </Stack>
            )}
          </TransformWrapper>
        </Stack>

        {/* グラフ表示 */}
        <Stack sx={{ display: showHeatmap === 0 ? 'none' : 'block' }}>
          <Plot
            data={[{
              'x': graphOption==='day' ? graphData.day.x : graphData.hour.x,
              'y': graphOption==='hour' ? graphData.hour.y : graphData.hour.y,
              'type': 'bar',
            }]}
            layout={{
              title: graphOption==='day' ? '日別平均滞在時間' : '時間別平均滞在時間',
              yaxis: { title: '分' },
              width: 1080,
              height: 720,
            }}
          />
        </Stack>
      </Stack>
    </Container>
  );
};



export default Main;
