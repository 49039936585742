import { useCallback, useState } from 'react';

const useLocalStorage = (key = 'floor_plan_details', initialValue = {}) => {
  const [floorPlanStorage, setValue] = useState<any>(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error(error);
      return initialValue;
    }
  });

  const getFloorPlanStorage = useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      if (item) {
        setValue(JSON.parse(item));
        return JSON.parse(item);
      }
      return {};
    } catch (error) {
      console.error(error);
      return {};
    }
  }, [key]);

  const setFloorPlanStorage = useCallback((value: any) => {
    try {
      const oldValue = getFloorPlanStorage();
      if (oldValue && Object.keys(oldValue).length) {
        setValue({ ...oldValue, ...value });
        window.localStorage.setItem('floor_plan_details', JSON.stringify({ ...oldValue, ...value }));
      } else {
        setValue(value);
        window.localStorage.setItem('floor_plan_details', JSON.stringify(value));
      }
    } catch (error) {
      console.error(error);
    }
  }, [getFloorPlanStorage]);

  const deleteFloorPlanStorage = useCallback(() => {
    try {
      setValue({});
      window.localStorage.removeItem('floor_plan_details');
    } catch (error) {
      console.error(error);
    }
  }, []);

  return { floorPlanStorage, setFloorPlanStorage, getFloorPlanStorage, deleteFloorPlanStorage };
};

export default useLocalStorage;