import styled from 'styled-components';

export const Layout = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`;
export const Content = styled.div`
  padding: 29px 21px 0 21px;
  background-color: hsl(202, 33%, 95%);
  flex: 1;
  overflow: auto;
  & > div {
    max-width: auto;
  }
  }
`;
export const MainContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;
