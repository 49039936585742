import axios from 'axios';
import { API_ENDPOINT } from './utils/Constants';

const Axios = axios.create({ baseURL: API_ENDPOINT });

// App
export const getConfig = () => Axios.get('getConfig');

// main
export const getHeatmap = (body: any) => Axios.post('heatmap', body);

// area
export const saveStaffAreas = (body: any) => Axios.post('staffAreas', body);
export const saveFilterAreas = (body: any) => Axios.post('filterAreas', body);

// filter
export const saveFilterConfig = (body: any) => Axios.post('filterConfig', body);
export const analyzeFilterSet = (body: any) => Axios.post('analyzeFilterSet', body);
export const trajectoryMap = (body: any) => Axios.post('trajectoryMap', body);

export const analyzeFilterSetTotalPeople = (body: any) => Axios.post('analyzeFilterSetTotalPeople', body);


export const gateAllData = () => Axios.get('gateAllData');


export const getPeopleData = async (params:any) => {
  return await Axios.get('getPeopleData', {params});
};

export const getPersonDetail = async (params:any) => {
  return await Axios.get('personDetail', {params});
};