import React, { useState, useReducer, useCallback, useEffect/*, useContext*/ } from 'react';
import useLocalStorage from '../hooks/useLocalstorage';
//import { URLPathContext } from '../App';
import { saveStaffAreas } from '../apis';

import styled from 'styled-components';
import { Container, Buttons, HeaderContainer, LineUIContainer, NoImage, ValidationAlert, LineUiImageWrapper } from '../style';
import { SmallInput, LineReducer, LineSetContext, LineUI, PageHeader, Button } from 'scorer-ui-kit';
import noImg from '../images/noimg.png';


const InputContainer = styled.div`
  display: flex;
  width: inherit;
  margin: 5px 0 0 0;
  justify-content: space-between;
  height: 102px;
`;

const AreaInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 0 10px 0;
`;
const SelectFieldBox = styled.select`
  width: 130px;
  height: 30px;
  padding: 0 0 0 10px;
  border-radius: 3px;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  border-color: #dfe1e2;
  color: hsla(207,5%,56.7%,1.000);
`;

const Position = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 0 10px 0;
`;

const SmallInputContainer = styled.div`
  display: flex;
  width: auto;
  margin: 0 30px 0 0; 
`;

const SmallInputBox = styled(SmallInput)`
  width: 130px;
  padding: 0 14px 0 0;
  border-radius: 3px;
  outline: none;
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 12px;
  margin-top: -10px;
`;

const InputHeader = styled.label`
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.35px;
  color: #7f7f7f;
  margin-bottom: 7px;
`;

const InputField = styled.div`
  display: flex;
  flex-direction: column;
`;


const LineUIWrapper = styled(LineUIContainer)`
  margin-bottom: 30px;
`;

const LineUIImageContainer = styled(LineUiImageWrapper)`
  margin-left: -30px;
  padding: 20px 30px;
`;


const StaffArea = () => {
  const [validationAlert, setValidationAlert] = useState<{ key?: any, message: string, type: 'success' | 'error' | 'warning' | 'info' | undefined }>({ key: new Date(), message: '', type: undefined });
  const [state, dispatch] = useReducer(LineReducer, []);
  const [selectedAreaName, setSelectedAreaName] = useState('');
  const { floorPlanStorage, setFloorPlanStorage } = useLocalStorage();
  const [staffAreas, setStaffAreas] = useState(floorPlanStorage.staffAreas ? floorPlanStorage.staffAreas : []);

  /*--------------------------------------------*/
  /*------------------エリア設定------------------*/
  /*--------------------------------------------*/
  // エリア初期化
  useEffect(() => {    
    if ( floorPlanStorage.staffAreas && floorPlanStorage.staffAreas.length !== 0 ){
      setSelectedAreaName(floorPlanStorage.staffAreas[0].data.name);
      const data = [];
      for (const i in floorPlanStorage.staffAreas){
        data.push(floorPlanStorage.staffAreas[i].data);
      }
      dispatch({
        type: 'LOAD',
        state: data
      });
    }else{
      setValidationAlert({ key: new Date(), message: '初回設定です。必ずエリアを一個以上保存してください。', type: 'warning'});
      setSelectedAreaName('StaffArea0');
      const data = {
        name: 'StaffArea0',
        points: [
          {'x': 0, 'y': 0},
          {'x': 300, 'y': 0},
          {'x': 300, 'y': 200},
          {'x': 0, 'y': 200}
        ]
      };
      dispatch({
        type: 'ADD_SET',
        data 
      });
      setStaffAreas([{'id': 0, 'data': data}]);
    }
  }, [floorPlanStorage, setStaffAreas]);

  // エリア追加
  const addArea = useCallback( () => {
    let newid = staffAreas.length;
    const new_staffAreas = [];
    const idList = [];
    for(let i = 0; i < staffAreas.length; i++) {  // 既存job id取得
      idList.push(staffAreas[i].id);
      new_staffAreas.push({'id': staffAreas[i].id, 'data': staffAreas[i].data});
    }
    for(let i = 0; i < idList.length; i++) {
      if (idList.indexOf(i) === -1){
        newid = i;
        break;
      }
    }

    const data = {
      name: 'StaffArea' + newid,
      points: [
        {'x': 0, 'y': 0},
        {'x': 300, 'y': 0},
        {'x': 300, 'y': 200},
        {'x': 0, 'y': 200}
      ]
    };
    dispatch({
      type: 'ADD_SET',
      data
    });
    new_staffAreas.push({'id': newid, 'data': data});
    setStaffAreas(new_staffAreas);
    setSelectedAreaName('StaffArea' + newid);
  }, [staffAreas]);

  // エリア削除
  const removeArea = useCallback( () => {    
    if (state.length <= 1) return false;
    
    for(const i in staffAreas){  // id削除
      if(staffAreas[i].data.name === selectedAreaName) {
        staffAreas.splice(Number(i), 1);
      }
    }

    for(const i in state){
      if(state[i].name === selectedAreaName) {
        const index = Number(i);
        dispatch({
          type: 'REMOVE_SET',
          index
        });
        if(index === 0) setSelectedAreaName((state[1].name));
        else setSelectedAreaName((state[0].name));
        break;
      }
    }
  }, [state, staffAreas, selectedAreaName]);

  // エリア名変更
  const renameArea = useCallback(({ target: { value } }) => {
    for(const i in staffAreas){
      if(staffAreas[i].data.name === selectedAreaName) {
        staffAreas[i].data.name = value;
      }
    }

    for(const i in state){
      if(state[i].name === selectedAreaName) {
        state[i].name = value;
        setSelectedAreaName(value);
        dispatch({
          type: 'LOAD',
          state
        });
        break;
      }
    }
  }, [state, staffAreas, selectedAreaName]);

  const addPoint = useCallback(() => {
    for(const i in state){
      if(state[i].name === selectedAreaName) {
        const index = Number(i);
        dispatch({
          type: 'ADD_POINT',
          index
        });
        break;
      }
    }
  },[state, selectedAreaName]);

  const removePoint = useCallback(() => {
    for(const i in state){
      if(state[i].name === selectedAreaName) {
        if(state[i].points.length <= 3) return;
        const index = Number(i);
        dispatch({
          type: 'REMOVE_POINT',
          index
        });
        break;
      }
    }
  },[state, selectedAreaName]);
  /*--------------------------------------------*/
  /*------------------エリア設定------------------*/
  /*--------------------------------------------*/

  // 現在編集中のarea
  const getSelectedArea : React.ChangeEventHandler<HTMLSelectElement> = (ev) => {
    for(const i in state){
      if(state[i].name === ev.target.value) {
        setSelectedAreaName(ev.target.value);
        break;
      }
    }
  };

  // DBに保存
  const saveAreas = useCallback(() => {
    const new_staffAreas = [];
    for(let i = 0; i < staffAreas.length; i++) {
      for(let k = 0; k < state.length; k++) {
        if (staffAreas[i].data.name === state[k].name){
          new_staffAreas.push({'id': staffAreas[i].id, 'data': state[k]});
        }
      }
    }

    saveStaffAreas({'staffAreas': new_staffAreas})
      .then(() => {
        setFloorPlanStorage({'staffAreas': new_staffAreas});
        setValidationAlert({ key: new Date(), message: '保存成功しました', type: 'success'});
      })
      .catch((error) => {
        setValidationAlert({ key: new Date(), message: '保存失敗しました'+error, type: 'error'});
      });
  }, [state, staffAreas, setFloorPlanStorage]);


  return (
    <Container>
      <HeaderContainer>
        <PageHeader title='スタッフエリア設定' />
        <Buttons>
          <Button design='primary' size='small' onClick={() => saveAreas()}>設定保存</Button>
        </Buttons>
      </HeaderContainer>

      {validationAlert.message !== '' &&
        <ValidationAlert type={validationAlert.type} message={validationAlert.message} />}

      {floorPlanStorage.original_image ?
        <>
          <InputContainer>
            <AreaInfo>
              <InputHeader>エリア追加</InputHeader>
              <SmallInputContainer>
                <InputField>
                  <Button design='primary' size='small' onClick={addArea}>追加</Button>
                </InputField>
              </SmallInputContainer>
            </AreaInfo>

            <AreaInfo>
              <InputHeader>エリア一覧</InputHeader>
              <SmallInputContainer>
                <InputField>
                  <SelectFieldBox value={selectedAreaName} onChange={getSelectedArea}>
                    {state.map((item: any)=>{
                      return <option key={item.name} value={item.name}>{item.name}</option>;
                    })}
                  </SelectFieldBox>
                </InputField>
              </SmallInputContainer>
            </AreaInfo>

            <Position>
              <InputHeader>エリア名設定</InputHeader>
              <SmallInputContainer>                
                <InputField>
                  <SmallInputBox label='' value={selectedAreaName} onChange={renameArea} />
                </InputField>
              </SmallInputContainer>
            </Position>

            <Position>
              <InputHeader>頂点数設定</InputHeader>
              <SmallInputContainer>
                <InputField>
                  <Button size='small' design='primary' onClick={removePoint}>－</Button>
                </InputField>
                <InputField>
                  <Button size='small' design='primary' onClick={addPoint}>＋</Button>
                </InputField>
              </SmallInputContainer>
            </Position>
            
            <AreaInfo>
              <InputHeader>エリア削除</InputHeader>
              <SmallInputContainer>
                <InputField>
                  <Button size='small' design='primary' onClick={removeArea}>削除</Button>
                </InputField>
              </SmallInputContainer>
            </AreaInfo>
            

          </InputContainer>

          <LineUIImageContainer height={window.innerHeight - 340} id='camera-position-img' isScrollbar={false}>
            <LineSetContext.Provider value={{ state, dispatch }}>
              <LineUIWrapper className='shadow'>
                <LineUI src={'data:image/jpeg;base64,'+floorPlanStorage.original_image} />
              </LineUIWrapper>
            </LineSetContext.Provider>
          </LineUIImageContainer>
        </> :
        <NoImage src={noImg} />}
    </Container>
  );
};

export default StaffArea;
