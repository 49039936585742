import React, { useState, useCallback, useEffect/*, useContext*/ } from 'react';
//import { URLPathContext } from '../App';
import { Container, HeaderContainer } from '../style';
import { TypeTable, PageHeader } from 'scorer-ui-kit';
import { getPersonDetail } from '../apis';
import { Pagination } from 'antd';
import type { PaginationProps } from 'antd';
import styled from 'styled-components';



const TableContainer = styled.div`
  table, th, td {
    border: 1px solid black;
    border-collapse: collapse;
  }
  th, td {
    padding: 5px;
  }
  th {
    text-align: left;
  }
  margin: 60px 0px;
`;

// const StyledTypeTable = styled.div`
//   & > div > div > div > div > div > div {
//     background-position: top center;
//   }
// `;

const StyledTypeTable = styled.div`
  & > div > div > div > div > div > img {
    object-position: 0% 0%; /* X then Y */
  }
`;

const ContainerTable = styled(Container)`
  max-width: 100%;
`;


const PersonDetails = () => {
  const [urlParams, setUrlParams] = useState<any>({});    
  const [hasParams, setHasParams] = useState(false);
  const [data, setData] = useState([]);
  const [pageContent, setPageContent] = useState([]);


  const [pageSize, setPageSize] = useState(100);
  const [page, setPage] = useState(1);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const showTotal: PaginationProps['showTotal'] = (total) => `Total ${total} items`;

  useEffect(() => {
    if (hasParams) return;
    const params = new URLSearchParams(window.location.search);
    if(params){
      setUrlParams({
        pid: params.get('pid'),
        // startDate: params.get('startDate'),
        // endDate: params.get('endDate')
      });
      setHasParams(true);
    }
  },[hasParams]);

    

  const onQuerryTbl = useCallback(async () => {
    try {      
      const response = await getPersonDetail({ pid: urlParams.pid/*, startDate: urlParams.startDate, endDate: urlParams.endDate*/});
      if(response.status === 200) {
        setData(response.data.data);
        setTableLoading(false);
      }
    } catch (error) {
      console.error((error as Error).message);
    }
  }, [urlParams]);


  useEffect(() => {
    setTableLoading(true);
    onQuerryTbl();
  },[onQuerryTbl]);


  const handleTableData = useCallback(() => {
    const arrData = [];
    const copiedObj = [...data].slice((page - 1) * pageSize, page * pageSize);
    
    if(data.length > 0){
      for (let i = 0; i < copiedObj.length; i++) {
        arrData.push({
          id: i,
          header: {
            image: 'data:image/jpeg;base64,'+copiedObj[i].body_image,
            mediaUrl: 'data:image/jpeg;base64,'+copiedObj[i].body_image,
            mediaType: 'img',
          },
          columns:[
            { text: copiedObj[i].zone_name },
            { text: copiedObj[i].tracking_id },
            { text: copiedObj[i].frame_time },
            { text: copiedObj[i].score },
          ]
        });
      }
      setPageContent(arrData);
    } else {
      arrData.push({
        id: 0,
        header: { image: '', mediaUrl: '', mediaType: 'img' },
        columns:
          [
            { text: '検索中' },
            { text: '検索中' },
            { text: '検索中' },
            { text: '検索中' }
          ]
      });
      setPageContent(arrData);
    }
  },[data, page, pageSize]);


  useEffect(() => {
    handleTableData();
  }, [handleTableData]);

  const columnConfig: any=[
    {
      cellStyle: 'normalImportance',
      header: 'カメラ名',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: '追跡ID',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: 'フレーム時間',
      alignment: 'center'
    },
    {
      cellStyle: 'normalImportance',
      header: 'マッチング値',
      alignment: 'center'
    }
  ];

  

  return (
    <ContainerTable>
      <HeaderContainer>
        <PageHeader title={`#${urlParams.pid}の行動履歴`} />
      </HeaderContainer>
      
      <TableContainer>
        <StyledTypeTable>
          <TypeTable
            hasThumbnail
            columnConfig={columnConfig}
            emptyTableText='There is currently no data'
            emptyTableTitle='No Data Available'
            isLoading={tableLoading}
            loadingText='Loading Data..'
            rows={pageContent}
          />
        </StyledTypeTable>
        {data.length > 0 && 
          <Pagination
            current={page}
            size='small'
            pageSize={pageSize}
            onChange={(newPage, newPageSize) => {
              setPageSize(newPageSize);
              setPage(pageSize !== newPageSize ? 1 : newPage);
            }}
            showSizeChanger
            pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000]}
            total={data.length}
            showTotal={showTotal}
            style={{ marginTop: '18px', textAlign: 'center'}}
          />}
      </TableContainer>


    </ContainerTable>
  );
};

export default PersonDetails;
