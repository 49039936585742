import React, { useState, useEffect, useContext } from 'react';
import useLocalStorage from '../../hooks/useLocalstorage';
import { URLPathContext } from '../../App';
import { trajectoryMap } from '../../apis';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import styled from 'styled-components';
import { PageHeader } from 'scorer-ui-kit';
import { HeaderContainer, Buttons, BackButton } from '../../style';
import { Content } from '../../components/Layout';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import Typography from '@mui/material/Typography';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Encoding from 'encoding-japanese';
import ExcelJS from 'exceljs';


const Container = styled(Content) <{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '295px'};
  height: 100%;
  width: 100%;
  max-width: 1200px;
  flex-direction: column;
  //padding: 60px 50px 25px 50px;
  transition: 0.2s;
`;

const ZoomContainer = styled.div`
  border: 1px solid;
  overflow: hidden;
`;

const HeatmapSVG = styled.svg`
  //display: flex;
  //objectFit: contain;
  width: 1080px;
  height: 720px;
`;

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '12px',
    border: '1px solid #dadde9',
  },
}));


// making lines smooth
function curvingLines(points: any) {
  const lineProperties = (pointA: any, pointB: any) => {
    const lengthX = pointB[0] - pointA[0];
    const lengthY = pointB[1] - pointA[1];
    return {
      length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
      angle: Math.atan2(lengthY, lengthX),
    };
  };

  const controlPointCalc = (
    current: any,
    previous: any,
    next: any,
    reverse: any
  ) => {
    const c = current;
    const p = previous ? previous : c;
    const n = next ? next : c;
    const smoothing = 0.2;
    const o = lineProperties(p, n);
    const rev = reverse ? Math.PI : 0;

    const x = c[0] + Math.cos(o.angle + rev) * o.length * smoothing;
    const y = c[1] + Math.sin(o.angle + rev) * o.length * smoothing;

    return [x, y];
  };

  const d = points.reduce((acc: any, e: any, i: any, a: any) => {
    if (i > 0) {
      const cs = controlPointCalc(a[i - 1], a[i - 2], e, false);
      const ce = controlPointCalc(e, a[i - 1], a[i + 1], true);
      return `${acc} C ${cs[0]},${cs[1]} ${ce[0]},${ce[1]} ${e[0]},${e[1]}`;
    } else {
      return `${acc} M ${e[0]},${e[1]}`;
    }
  }, '');
  return d;
}


const AnalyzeStep4: React.FC<{}> = () => {
  const { setNowURL } = useContext(URLPathContext);
  const { floorPlanStorage } = useLocalStorage();
  const [trailData, setTrailData] = useState([]);
  const [pointData, setPointData] = useState([]);
  const [title, setTitle] = useState('対象人物別軌跡マップ');
  const [loading, setLoading] = useState(true);

  const mapImg = floorPlanStorage.original_image ? 'data:image/jpeg;base64,'+floorPlanStorage.original_image : '';
  const [mapWitdh, setMapWitdh] = useState(0);
  const [mapHeight, setMapHeight] = useState(0);

  const [showDebugInfo, setShowDebugInfo] = useState(false);
  const [gateInfo, setGateInfo] = useState<any>([]);  

  const [urlParams, setUrlParams] = useState<any>({});    
  const [hasParams, setHasParams] = useState(false);
    
  // get bg image size
  useEffect(() => {
    const loadImage = (src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = (e) => reject(e);
        img.src = src;
      });
    };
    loadImage(mapImg)
      .then((res: { width: number; height: number }) => {
        setMapWitdh(res.width);
        setMapHeight(res.height);
      })
      .catch(e => console.error('onload error', e));
  }, [mapImg]);

  useEffect(() => {
    if (hasParams) return;
    const params = new URLSearchParams(window.location.search);
    if(params){
      setUrlParams({
        pid: params.get('pid'),
        startDate: params.get('startDate'),
        endDate: params.get('endDate'),
        analyzeType : params.get('analyzeType'),
        entranceGate: params.get('entranceGate'),
        exitGate: params.get('exitGate')
      });
      setHasParams(true);
    }
  },[hasParams]);

  useEffect(() => {
    if (!hasParams) return;
    const start = performance.now();
    trajectoryMap({
      'pid': urlParams.pid,
      'startDate': urlParams.startDate,
      'endDate': urlParams.endDate,
      'analyzeType': urlParams.analyzeType,
    })
      .then((res) => {
        const react_elapsed_time = (performance.now() - start) / 1000;
        setTrailData(res.data.trail_data);
        setPointData(res.data.point_data);
        setTitle('トラッキング期間：'+res.data.point_data[0][3]+'　～　'+res.data.point_data[res.data.point_data.length-1][3]);
        setGateInfo(res.data.gateInfo);
        setLoading(false);


        // debug time
        const react_total_time = (performance.now() - start) / 1000;
        const elapsed_time_select_db = res.data.debug_info[0].elapsed_time_select_db;
        const elapsed_time_trail_data = res.data.debug_info[0].elapsed_time_trail_data;
        const api_total_time = res.data.debug_info[0].total_time;
        
        console.log('—————————————————DEBUG INFO——————————————————');
        console.log(`heatmapと軌跡データを取得するために、${react_total_time.toFixed(2)}秒掛かりました`);
        console.log('----UI部分の詳細時間----');
        console.log(`総処理時間： ${react_total_time.toFixed(2)}`);
        console.log(`apiからのデータ待ち時間： ${(react_elapsed_time).toFixed(2)}`);
        console.log(`uiとapiのデータ転送時間： ${(react_elapsed_time-api_total_time).toFixed(2)}`);
        console.log('********************');
        console.log('----apiの詳細時間----');
        console.log(`総処理時間： ${api_total_time.toFixed(2)}`);
        console.log(`DB検索時間： ${elapsed_time_select_db.toFixed(2)}`);
        console.log(`軌跡データ処理時間： ${elapsed_time_trail_data.toFixed(2)}`);
        console.log('********************');
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [hasParams, urlParams]);
    
  const entranceGateInfo = gateInfo.filter((gate: any) => gate.camera === urlParams.entranceGate && gate.type === 1);
  const exitGateInfo = gateInfo.filter((gate: any) => gate.camera === urlParams.exitGate && gate.type === 4);

  const downloadCSV = async() => {

    const workbook = new ExcelJS.Workbook();
    workbook.addWorksheet('sheet1');
    const worksheet = workbook.getWorksheet('sheet1');

    /*
    worksheet.columns = [
      { header: 'JobID', key: 'date' },
      { header: floorPlanStorage.targetJob.job_id, key: 'x' },
      { header: 'PersonID', key: 'y' },
      { header: floorPlanStorage.trajectoryPID, key: 'tid' },
      { header: '', key: 'camera' },
    ];
    worksheet.addRows([
      {
        date: 'トラッキング開始時刻',
        x: pointData[0][3],
        y: 'トラッキング終了時刻',
        tid: pointData[pointData.length-1][3],
        camera: '',
      },
    ]);
    worksheet.addRows([
      {
        date: 'スタッフ判定',
        x: floorPlanStorage.trajectoryIsStaff === 1 ? 'true' : 'false',
        y: '',
        tid: '',
        camera: '',
      },
    ]);*/
    worksheet.columns = [
      { header: 'PersonID', key: 'date'},
      { header: 'トラッキング開始時刻', key: 'x'},
      { header: 'トラッキング終了時刻', key: 'y'},
      { header: 'スタッフ判定', key: 'tid'},
      { header: '', key: 'camera'},
    ];

    worksheet.addRows([
      {
        date: floorPlanStorage.trajectoryPID,
        x: pointData[0][3],
        y: pointData[pointData.length-1][3],
        tid: floorPlanStorage.trajectoryIsStaff === 1 ? 'true' : 'false',
        camera: ''
      }
    ]);

    worksheet.addRows([{},{},
      {
        date: '時刻',
        x: 'x座標',
        y: 'y座標',
        tid: 'tracking id',
        camera: 'カメラ名',
      },
    ]);

    for(let i=0; i< pointData.length; i++) {
      worksheet.addRows([
        {
          date: pointData[i][3],
          x: pointData[i][0],
          y: pointData[i][1],
          tid: pointData[i][5],
          camera: pointData[i][6],
        },
      ]);
    }    

    //const uint8Array = await workbook.csv.writeBuffer();
    const uint8Array = new Uint8Array(
      Encoding.convert((await workbook.csv.writeBuffer() as Uint8Array), {
        from: 'UTF8',
        to: 'SJIS'
      })
    );

    const blob = new Blob([uint8Array], { type: 'application/octet-binary' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'sampleData.csv';
    a.click();
    a.remove();
  };


  return (
    <Container>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
      <HeaderContainer>
        <PageHeader title={`(Person ID：${urlParams.pid}) 対象人物別軌跡マップ`} />
        <Buttons>
          <BackButton size='small' design='primary' onClick={downloadCSV}>CSVに保存</BackButton>
          <BackButton size='small' design='primary' onClick={()=>setNowURL('/analyze-result')}>戻る</BackButton>
        </Buttons>
      </HeaderContainer>
      <Stack spacing={2}>
        <p>{title}</p>
      </Stack>

      
      <Stack spacing={2}>
        <TransformWrapper initialScale={1}>
          {({ zoomIn, zoomOut, resetTransform, /*...rest*/ }) => (
            <Stack direction='row' spacing={2}>
              <Stack>
                <Button variant='outlined' startIcon={<ZoomInIcon />} onClick={() => zoomIn()}>
                  拡大
                </Button>
                <Button variant='outlined' startIcon={<ZoomOutIcon />} onClick={() => zoomOut()}>
                  縮小
                </Button>
                <Button variant='outlined' onClick={() => resetTransform()}>
                  復元
                </Button>
              </Stack>
              <ZoomContainer>
                <TransformComponent>
                  <HeatmapSVG viewBox={'0 0 '+mapWitdh+' '+mapHeight} id='mapSVG'>
                    <image href={mapImg} />
                    {entranceGateInfo?.map((item: any, index) => {
                      return(
                        <g key={index}>
                          <rect x={item.cam_pos.x} y={item.cam_pos.y} rx='3' ry='3' width='40' height='30' fill='lightgreen' />
                          <text x={item.cam_pos.x + 20} y={item.cam_pos.y+15} textAnchor='middle' stroke='black' strokeWidth='1px' dy='.3em'>入口</text>
                        </g>
                      );
                    })}
                    {exitGateInfo?.map((item: any, index) => {
                      return(
                        <g key={index}>
                          <rect x={item.cam_pos.x} y={item.cam_pos.y} rx='3' ry='3' width='40' height='30' fill='red' />
                          <text x={item.cam_pos.x+20} y={item.cam_pos.y+15} textAnchor='middle' stroke='black' strokeWidth='1px' dy='.3em'>出口</text>
                        </g>
                      );
                    })}
                    <path
                      d={curvingLines(trailData)}
                      fill='none'
                      stroke='red'
                      strokeWidth='2'
                    />
                    {pointData.map((item: any,i) => {
                      if (i === pointData.length-1) setShowDebugInfo(false);
                      return (
                        <HtmlTooltip
                          key={'Tooltip'+i}
                          title={
                            <React.Fragment>
                              <>
                                <Typography color='inherit'>{item[3]}</Typography>
                                <img src={'data:image/jpeg;base64,'+item[2]} alt='' />
                                {/*debug info*/}
                                <Typography color='inherit'>---DEBUG INFO---</Typography>
                                <Typography color='inherit'>x：{item[0]}    y：{item[1]}</Typography>
                                <Typography color='inherit'>person id：{item[4]}</Typography>
                                <Typography color='inherit'>tracking id：{item[5]}</Typography>
                                <Typography color='inherit'>camera：{item[6]}</Typography>
                                {showDebugInfo &&
                                  console.log('---point_'+(i+1)+' INFO---:\n',
                                    'frame_time', item[3], '\n',
                                    'x：', item[0], '  y：', item[1], '\n',
                                    'person id：', item[4], '\n',
                                    'tracking id：', item[5], '\n',
                                    'camera：', item[6], '\n',
                                  )}
                              </>
                            </React.Fragment>
                          }
                        >
                          <circle
                            cx={item[0]}
                            cy={item[1]}
                            r={i===0 ? '5' : i === pointData.length-1 ? '5' : '3'}
                            strokeWidth={i===0 ? '2' : i === pointData.length-1 ? '2' : '1'}
                            stroke={i===0 ? 'blue' : i === pointData.length-1 ? 'green' : 'black'}
                            fill={i===0 ? 'green': i === pointData.length-1 ? 'yellow' : 'red'}
                          />
                        </HtmlTooltip>
                      );
                    })}
                  </HeatmapSVG>
                </TransformComponent>
              </ZoomContainer>
            </Stack>
          )}
        </TransformWrapper>
      </Stack>
    </Container>
  );
};

export default AnalyzeStep4;
