import React, { useState, useCallback, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useLocalStorage from '../../hooks/useLocalstorage';
import { URLPathContext } from '../../App';
import { analyzeFilterSet, analyzeFilterSetTotalPeople } from '../../apis';

import styled from 'styled-components';
import { Buttons, HeaderContainer, BackButton } from '../../style';
import { TypeTable, Content, PageHeader, Button } from 'scorer-ui-kit';
import { ITableColumnConfig, ITypeTableData } from 'scorer-ui-kit/dist/Tables';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { TablePagination } from '@mui/material';

// tien duc
import moment from 'moment';
import Table from '@material-ui/core/Table';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import '../../App.css';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


const useStyles = makeStyles({
  tableCell: {  
    width: 350,
    '&.MuiTableCell-root': {
      fontWeight: 600,
      fontFamily: 'Monorale,Hiragino Sans,"ヒラギノ角ゴシック",Hiragino Kaku Gothic ProN,"ヒラギノ角ゴ ProN W3",Roboto,YuGothic,"游ゴシック",Meiryo,"メイリオ",sans-serif'
    },
    textShadow: '0 0 10px rgba(255, 255, 255, 0.8)',
    color: '#727980'
  },
  table: {
    '&.MuiTable-root': {
      width: '60%'
    }
  }
});


const Container = styled(Content) <{ marginLeft?: string }>`
  display: flex;
  flex: 1;
  margin-left: ${({ marginLeft }) => marginLeft ? marginLeft : '295px'};
  height: 100%;
  max-width: 700px;
  flex-direction: column;
  padding: 67px 50px 25px 45px;
  transition: 0.2s;
`;

// tien duc
const Title = styled.div`
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
  font-family: ${({ theme }) => theme.fontFamily.ui};
  font-size: 18px;
  font-weight: 600;
  color: #727980;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const InfoTable = styled.div`
  max-width: 700px;
`;

const TableArea = styled.div`
  font-size: 14px;
  font-weight: normal;
  overflow: auto;
  padding: 10px 5px;
  word-break: normal;
`;


const columnConfig: ITableColumnConfig[] = [
  {
    header: 'PID',
    sortable: false,
    cellStyle: 'highImportance',
    alignment: 'center',
  },
  {
    header: '入口カメラ',
    sortable: false,
    cellStyle: 'highImportance',
    alignment: 'center',
  },
  {
    header: '滞在時間',
    sortable: false,
    cellStyle: 'highImportance',
    alignment: 'center',
  },
  {
    header: '軌跡',
    sortable: false,
    cellStyle: 'highImportance',
    alignment: 'center',
  },
  /*{
    header: 'FaceRecord',
    sortable: false,
    cellStyle: 'highImportance',
    alignment: 'center',
  },*/
  {
    header: 'スタッフ',
    sortable: false,
    cellStyle: 'highImportance',
    alignment: 'center',
  },
];

const StyledTypeTable = styled.div`
  & > div > div > div > div > div > div {
    background-position: top center;
    background-size: cover;
  }
`;

const AnalyzeStep3 = () => {
  const { setNowURL } = useContext(URLPathContext);
  const [ loading, setLoading ] = useState(false);
  const { floorPlanStorage, setFloorPlanStorage } = useLocalStorage();
  const [ analyzeResult, setAnalyzeResult ] = useState(floorPlanStorage.analyze_result ? floorPlanStorage.analyze_result : []);
  const [ rowData, setRowData ] = useState<ITypeTableData>([]);
  const [ page, setPage ] = useState(floorPlanStorage.analyze_result_page ? floorPlanStorage.analyze_result_page : 0);
  const [ rowsPerPage, setRowsPerPage ] = useState(floorPlanStorage.analyze_result_rowsPerPage ? floorPlanStorage.analyze_result_rowsPerPage : 10);
  const [ signagePosition ] = useState(floorPlanStorage.signagePosition);
  const [ analyzeData ] = useState({
    'filterSet': floorPlanStorage.filterSets[floorPlanStorage.selectedFilterSet],
    'areas': floorPlanStorage.filterAreas,
    'staffAreas': floorPlanStorage.staffAreas,
    'startDate': floorPlanStorage.datePicker_job[0],  // tien duc
    'endDate': floorPlanStorage.datePicker_job[1],
    'selectedSearchType': floorPlanStorage.selectedJobType,
    'signageData': [],
    'selectedGate': floorPlanStorage.selectedGate
  });

  // tien duc
  const classes = useStyles();
  const [ activeStep, setActiveStep ] = useState(0);
  const [ totalPeople, setTotalPeople ] = useState(0);


  // TablePagination
  const handleChangePage = (_event: any, newPage: any) => {
    setPage(newPage);
    setFloorPlanStorage({'analyze_result_page': newPage});
  };
  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
    setFloorPlanStorage({'analyze_result_rowsPerPage': parseInt(event.target.value)});
    setFloorPlanStorage({'analyze_result_page': 0});
  };

  const showTrajectoryMap = useCallback((pid, isStaff, analyzeType) => {
    setFloorPlanStorage({'trajectoryPID': pid, 'trajectoryIsStaff': isStaff, 'trajectoryAnalyzeType': analyzeType});
    //setNowURL('/analyze-detail');
  }, [setFloorPlanStorage/*, setNowURL*/]);

  // table row creater
  useEffect(() => {
    const new_rowData = [];
    if (analyzeResult.length === 0){
      new_rowData.push(
        {
          id: 0,
          header: { image: '', mediaUrl: '', mediaType: 'img' },
          columns:
          [
            { text: '該当人物検索中' },
            { text: '該当人物検索中' },
            { text: '該当人物検索中' },
            { text: '該当人物検索中' },
            /*{ text: '該当人物検索中' },*/
            { text: '該当人物検索中' },
          ]
        });
    } else if (analyzeResult.length === 1 && analyzeResult[0].person_id === '結果なし'){
      new_rowData.push(
        {
          id: 0,
          header: { image: '', mediaUrl: '', mediaType: 'img' },
          columns:
          [
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            { text: '結果なし' },
            /*{ text: '結果なし' },*/
            { text: '結果なし' },
          ]
        });
      setLoading(false);
    } else {
      const max = page * rowsPerPage + rowsPerPage > analyzeResult.length ? analyzeResult.length : page * rowsPerPage + rowsPerPage;
      for (let i=page * rowsPerPage; i<max; i++) {
        new_rowData.push(
          {
            id: i,
            header: {
              image: 'data:image/jpeg;base64,'+analyzeResult[i].body_image,
              mediaUrl: 'data:image/jpeg;base64,'+analyzeResult[i].body_image,
              mediaType: 'img',
            },
            columns:
            [
              { text: analyzeResult[i].person_id },
              { text: analyzeResult[i].entrance_gate },
              { text: analyzeResult[i].duration },
              { customComponent: 
  <Link 
    to={{ 
      pathname: '/analyze-detail', 
      search: `?pid=${analyzeResult[i].person_id}&startDate=${floorPlanStorage.datePicker_job[0]}&endDate=${floorPlanStorage.datePicker_job[1]}&analyzeType=Body&entranceGate=${analyzeResult[i].entrance_gate}&exitGate=${analyzeResult[i].exit_gate}`
    }} target='_blank' onClick={()=>showTrajectoryMap(analyzeResult[i].person_id, analyzeResult[i].staff_flag, 'Body')} replace
  >[行動履歴]
  </Link>},              
              /*{ customComponent: 
  <Link 
    to={{ 
      pathname: '/analyze-detail', 
      search: `?pid=${analyzeResult[i].person_id}&startDate=${floorPlanStorage.datePicker_job[0]}&endDate=${floorPlanStorage.datePicker_job[1]}&analyzeType=Face&entranceGate=${analyzeResult[i].entrance_gate}&exitGate=${analyzeResult[i].exit_gate}`
    }} target='_blank' onClick={()=>showTrajectoryMap(analyzeResult[i].person_id, analyzeResult[i].staff_flag, 'Face')} replace
  >[履歴]
  </Link>},*/
              { text: analyzeResult[i].staff_flag === 1 ? '〇' : '×' },
            ]
          });
      }
      setLoading(false);
    }
    setRowData(new_rowData);
  },[page, rowsPerPage, analyzeResult, showTrajectoryMap, floorPlanStorage.datePicker_job, floorPlanStorage.selectedJobType]);

  // tien duc
  // average time //
  const timeArr = [];
  for (let i = 0; i < analyzeResult.length; i++) {
    timeArr.push(analyzeResult[i].duration);
  }

  const totalDurations = timeArr.slice(1)
    .reduce((prev, cur) => moment.duration(cur).add(prev),
      moment.duration(timeArr[0]));
  const avgTime = `${moment.utc(totalDurations.asMilliseconds()/analyzeResult.length).format('HH:mm:ss')}`;
  ////////////////

  const goback = () => {
    setFloorPlanStorage({'analyze_result_page': 0});
    setNowURL('/analyze-step2');
  };

  
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  

  // get table row data
  useEffect(() => {
    const start_time = performance.now();
    setLoading(true);
    const result = JSON.parse(JSON.stringify(analyzeData));
  
    // サイネージ計算
    for (let i = 0; i < signagePosition.length; i++) {
      const start_x = signagePosition[i].signage_line_points.points[0].x;
      const start_y = signagePosition[i].signage_line_points.points[0].y;
      const end_x = signagePosition[i].signage_line_points.points[1].x;
      const end_y = signagePosition[i].signage_line_points.points[1].y;
      const offset = 20;
      const angle = Math.atan2(end_y - start_y, end_x - start_x);
      const midX = (start_x + end_x) / 2;
      const midY = (start_y + end_y) / 2;
      const direction = angle - Math.PI / 2;
      const triMidX = parseInt((midX + offset * Math.cos(direction)).toString(), 10);
      const triMidY = parseInt((midY + offset * Math.sin(direction)).toString(), 10);

      result.signageData.push([
        signagePosition[i].signage_line_points.points[0], signagePosition[i].signage_line_points.points[1],
        signagePosition[i].viewing_angle, signagePosition[i].visible_distance,
        [triMidX, triMidY]
      ]);
    }

    for (let i = 0; i < result.filterSet.filterList.length; i++) {
      if(i > activeStep){
        result.filterSet.filterList.splice(i, result.filterSet.filterList.length);
      }
    }

    const elapsed_start_analyzeFilterSet = performance.now();
    analyzeFilterSet({ result })
      .then((response) => {
        const react_api_analyzeFilterSet_time = (performance.now() - elapsed_start_analyzeFilterSet) / 1000;
        setFloorPlanStorage({'analyze_result': response.data.data});
        setAnalyzeResult(response.data.data.length !== 0 ? response.data.data : [{
          'body_image': null,
          'duration': null,
          'person_id': '結果なし',
          'staff_flag': null,
        }]);

        // debug time
        const react_total_time = (performance.now() - start_time) / 1000;
        const debug_info_analyzeFilterSet = response.data.debug_info[0];
        const elapsed_time_select_db = debug_info_analyzeFilterSet.elapsed_time_select_db;
        const elapsed_time_id_list = debug_info_analyzeFilterSet.elapsed_time_id_list;
        const elapsed_time_select_PersonData = debug_info_analyzeFilterSet.elapsed_time_select_PersonData;
        const elapsed_time_response = debug_info_analyzeFilterSet.elapsed_time_response;
        const total_time = debug_info_analyzeFilterSet.total_time;
        console.log('—————————————————DEBUG INFO——————————————————');
        console.log(`分析結果データを取得するために、${react_total_time.toFixed(2)}秒掛かりました`);
        console.log('----UI部分の詳細時間----');
        console.log(`総処理時間： ${react_total_time.toFixed(2)}`);
        console.log(`apiからの対象人数のデータ待ち時間： ${react_api_analyzeFilterSet_time.toFixed(2)}`);
        console.log(`uiとapiのデータ転送時間： ${(react_api_analyzeFilterSet_time-total_time).toFixed(2)}`);
        console.log('********************');
        console.log('----対象人数のapiの詳細時間----');
        console.log(`総処理時間： ${total_time.toFixed(2)}`);
        console.log(`DB検索時間： ${elapsed_time_select_db.toFixed(2)}`);
        console.log(`条件満たした人物を算出する時間： ${elapsed_time_id_list.toFixed(2)}`);
        console.log(`一覧表示用のDB検索時間： ${elapsed_time_select_PersonData.toFixed(2)}`);
        console.log(`結果まとめ時間： ${elapsed_time_response.toFixed(2)}`);
        console.log('********************');
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });

    analyzeFilterSetTotalPeople({ result })
      .then((response) => {
        setTotalPeople(response.data.total_people);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, [analyzeData, activeStep, setFloorPlanStorage, signagePosition]);

    
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color='inherit' />
      </Backdrop>

      <Container>
        <HeaderContainer>
          <PageHeader title='分析結果' />
          <Buttons>
            <BackButton size='small' design='secondary' onClick={goback}>戻る</BackButton>
          </Buttons>
        </HeaderContainer>


        {/* tien duc */}
        <Title>〇集計情報</Title>
        <InfoTable>
          <Table className={classes.table}>  
            <tbody>    
              <TableRow className='row-style'>
                <TableCell className={classes.tableCell} variant='head'>集計日</TableCell>
                <TableCell className={classes.tableCell} variant='head'>:</TableCell>
                <TableCell className={classes.tableCell}>{moment(analyzeData.startDate).format('YYYY-MM-DD')} - {moment(analyzeData.endDate).format('YYYY-MM-DD')}</TableCell>
              </TableRow>
              <TableRow className='row-style'>
                <TableCell className={classes.tableCell} variant='head'>集計時間</TableCell>
                <TableCell className={classes.tableCell} variant='head'>:</TableCell>
                <TableCell className={classes.tableCell}>{moment(analyzeData.startDate).format('HH:mm')} - {moment(analyzeData.endDate).format('HH:mm')}</TableCell>
              </TableRow>      
              <TableRow className='row-style'>
                <TableCell className={classes.tableCell} variant='head'>平均滞在時間</TableCell>
                <TableCell className={classes.tableCell} variant='head'>:</TableCell>
                <TableCell className={classes.tableCell}>{analyzeResult.length === 0 || avgTime === '00:00:00' ? '結果なし' : avgTime}</TableCell>
              </TableRow>
              <TableRow className='row-style'>
                <TableCell className={classes.tableCell} variant='head'>検知人数</TableCell>
                <TableCell className={classes.tableCell} variant='head'>:</TableCell>
                <TableCell className={classes.tableCell}>{totalPeople === 0 || undefined ? '結果なし' : totalPeople +'人'}</TableCell>
              </TableRow>
              <TableRow className='row-style'>
                <TableCell className={classes.tableCell} variant='head'>スタッフ表示</TableCell>
                <TableCell className={classes.tableCell} variant='head'>:</TableCell>
                <TableCell className={classes.tableCell}>{analyzeData.filterSet.filterStaff === 'ALL' ? '全員' : analyzeData.filterSet.filterStaff === 'NoStaff' ? 'スタッフ非表示' : 'スタッフのみ表示'}</TableCell>
              </TableRow> 
              <TableRow className='row-style'>
                <TableCell className={classes.tableCell} variant='head'>対象人数</TableCell>
                <TableCell className={classes.tableCell} variant='head'>:</TableCell>
                <TableCell className={classes.tableCell}>{analyzeResult.length === 1 && analyzeResult[0].person_id === '結果なし' ? '結果なし' : analyzeResult.length +'人'}</TableCell>
              </TableRow>
            </tbody>      
          </Table>
        </InfoTable>

        <Title>〇ファネル分析</Title>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep}>
            {analyzeData.filterSet.filterList.map((label, index) => {
              return (
                <Step key={index} onClick={() => setActiveStep(index)}>
                  <StepLabel>{label.name}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {analyzeData.filterSet.filterList.length !== 1 ?
            <>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  color='inherit'
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  size='small'
                >
                  戻る
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button
                  onClick={handleNext}
                  disabled={activeStep === analyzeData.filterSet.filterList.length - 1}
                  size='small'
                >
                  次
                </Button>
              </Box>
            </> : ''}
        </Box>

        <Title>〇対象人物リスト（全体）</Title>
        <TableArea>
          <StyledTypeTable>
            <TypeTable
              hasThumbnail
              columnConfig={columnConfig}
              rows={rowData}
            />
          </StyledTypeTable>
          <TablePagination
            component='div'
            count={analyzeResult.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[10,20,50,100]}
            labelRowsPerPage='ページあたりの行数:'
          />
        </TableArea>
      </Container>
    </>
  );
};

export default AnalyzeStep3;